var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { AccountTransactionApi } from "./account.transactions.api";
import { Storage } from "../../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../../components/modal/modal";
import EyeIcon from '../../../../assets/feather/eye.svg';
import EyeOffIcon from '../../../../assets/feather/eye-off.svg';
import '../../account.css';
import { initialLeftMenu } from "../../../../lib/misc.functions";
import { doLogout } from '../../../../services/login.services';
import { useAuthContext } from "../../../../store/login.store";
import paths from '../../../../app/paths';
import { betSlipVisibleSubject } from '../../../../store/betslip.store';
import { useNavigate } from "react-router-dom";
export default function ChangePassword() {
    var token = Storage.get('token');
    var setIsAuthenticated = useAuthContext().setIsAuthenticated;
    var navigate = useNavigate();
    useEffect(function () {
        initialLeftMenu(false);
    }, []);
    var _a = useForm(), register = _a.register, handleSubmit = _a.handleSubmit, control = _a.control, getValues = _a.getValues, errors = _a.formState.errors;
    var onSubmit = function (data) {
        callChangePasswordApi(data);
    };
    var callChangePasswordApi = function (data) {
        data.token = token;
        AccountTransactionApi.changePassword(data).then(function (result) {
            if (result.status) {
                doLogout();
                var pathname = window.location.pathname.slice(1);
                if (pathname === paths.LOGIN || pathname === '') {
                    pathname = '/';
                }
                setIsAuthenticated(false);
                betSlipVisibleSubject.next(false);
                navigate('/giris-yap');
                modalServiceSubject.next({
                    title: "Güncellendi!",
                    content: "Şifre başarıyla güncellendi.",
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
            else {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: result.message,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            }
        });
    };
    var togglePassword = function (id) {
        var element = document.getElementById(id);
        var elementInput = document.getElementById(id + "Input");
        if (element.src.includes("eye-off")) {
            element.src = EyeIcon;
            elementInput.type = "text";
        }
        else {
            element.src = EyeOffIcon;
            elementInput.type = "password";
        }
    };
    return (_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u015Eifre De\u011Fi\u015Ftir" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs("div", __assign({ className: "table row" }, { children: [_jsx("div", __assign({ className: "col-lg-7 col-md-12 col-sm-12" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mt-1 mb-2" }, { children: [_jsx("label", { children: "Mevcut \u015Eifre" }, void 0), _jsx("input", __assign({ id: "defaultPasswordInput" }, register('current_pswd', { required: true, minLength: 6, }), { className: "form-control", type: "password" }), void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2", onClick: function () { return togglePassword("defaultPassword"); } }, { children: _jsx("img", { id: "defaultPassword", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.current_pswd && errors.current_pswd.type === "required" &&
                                                _jsx("span", __assign({ className: "text-validation mb-2" }, { children: "Mevcut \u015Fifreniz gereklidir." }), void 0), errors.current_pswd && errors.current_pswd.type === "minLength" &&
                                                _jsx("span", __assign({ className: "text-validation mb-2" }, { children: "\u015Eifre \u00E7ok k\u0131sa!" }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-1" }, { children: [_jsx("label", { children: "Yeni \u015Eifre" }, void 0), _jsx(Controller, { name: "new_pswd", control: control, rules: {
                                                    required: "Şifre belirlemelisiniz",
                                                }, render: function () { return _jsx("input", __assign({ id: "newPasswordInput" }, register('new_pswd', { required: true, minLength: 6, }), { name: "new_pswd", type: "password", className: "form-control" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2", onClick: function () { return togglePassword("newPassword"); } }, { children: _jsx("img", { id: "newPassword", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.new_pswd && errors.new_pswd.type === "required" &&
                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre gereklidir." }), void 0), errors.new_pswd && errors.new_pswd.type === "minLength" &&
                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre \u00E7ok k\u0131sa!" }), void 0), errors.new_pswd && _jsx("p", { children: errors.new_pswd.message }, void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-1" }, { children: [_jsx("label", { children: "Yeni \u015Eifre Tekrar\u0131" }, void 0), _jsx(Controller, { name: "new_pswd_repeat", control: control, rules: {
                                                    required: "Şifre belirlemelisiniz",
                                                    //@ts-ignore
                                                    validate: function (value) {
                                                        if (value === getValues()["new_pswd"]) {
                                                            return true;
                                                        }
                                                        else {
                                                            return (_jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131, \u015Fifre ile e\u015Fle\u015Fmelidir." }), void 0));
                                                        }
                                                    }
                                                }, render: function () { return _jsx("input", __assign({ id: "newPassword2Input" }, register('new_pswd_repeat', { required: true }), { name: "new_pswd_repeat", type: "password", className: "form-control" }), void 0); } }, void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2 position-absolute", onClick: function () { return togglePassword("newPassword2"); } }, { children: _jsx("img", { id: "newPassword2", className: "eye-icon", src: EyeOffIcon, height: 16, width: 16 }, void 0) }), void 0), errors.new_pswd_repeat && errors.new_pswd_repeat.type === "required" &&
                                                _jsx("span", __assign({ className: "text-validation" }, { children: "\u015Eifre tekrar\u0131 gereklidir." }), void 0), errors.new_pswd_repeat && _jsxs("p", { children: [" ", errors.new_pswd_repeat.message] }, void 0)] }), void 0), _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit", value: "G\u00FCncelle" }, void 0)] }), void 0) }), void 0), _jsxs("div", __assign({ className: "col-lg-5 col-md-12 col-sm-12 mt-5 hidden-sm" }, { children: [_jsx("div", __assign({ className: "mb-1" }, { children: "\u015Eifrenizi de\u011Fi\u015Ftirmek i\u00E7in \u00F6nce mevcut \u015Fifre girilmelidir." }), void 0), " ", _jsx("br", {}, void 0), _jsx("div", __assign({ className: "mb-1" }, { children: "Yeni \u015Fifreniz, minimum 6 karakter i\u00E7ermelidir." }), void 0), " ", _jsx("br", {}, void 0), _jsx("div", __assign({ className: "mb-1" }, { children: " \u015Eifrenizi g\u00FCncelledikten bir sonraki giri\u015Finizde yeni \u015Fifrenizle giri\u015F yapabilirsiniz." }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0));
}
