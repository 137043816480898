var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Storage } from "../../../../lib/localstorege.service";
import { AccountTransactionApi } from "./account.transactions.api";
import { loginSubject, member } from "../../../../store/login.store";
import { useForm } from "react-hook-form";
import EmailUpdate from "../../widgets/modal/email.update";
import MobileUpdate from "../../widgets/modal/mobile.update";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { sportsMenu, useForceUpdate } from "../../../../lib/misc.functions";
import { teamList } from '../../../../store/static.pages.store';
import CopyIcon from "../../../../assets/feather/copy.svg";
export default function MembershipInformation() {
    var forceUpdate = useForceUpdate();
    var token = Storage.get('token');
    var _a = useState(teamList), teamsList = _a[0], setTeamsList = _a[1];
    var teamListData = {
        sport_type: 1,
        token: token
    };
    useEffect(function () {
        if (teamList.length < 1) {
            AccountTransactionApi.teamList(teamListData).then(function (result) {
                if (result.status) {
                    teamList.push.apply(teamList, result.data);
                    setTeamsList(result.data);
                }
                else {
                    modalServiceSubject.next({
                        title: "Hata!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            });
        }
    }, []);
    useEffect(function () {
        // const leftSideMenu: any = document.getElementById('leftSideMenu')
        // leftSideMenu.style.display = "none";
        // const rightSideWrapper: any = document.getElementsByClassName('right-side-wrapper');
        // console.log(rightSideWrapper);
        // rightSideWrapper[0].style.width = '100%';
        // rightSideWrapper[0].style.marginLeft = '10px';
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function () {
            forceUpdate();
            reset({
                city: member.city,
                county: member.county,
                address: member.address,
                favorite_team: member.favorite_team_id,
                share_option: member.share_option,
                sms_notification: member.sms_notification,
                push_notification: member.push_notification,
                mail_notification: member.mail_notification,
                call_notification: member.call_notification,
                campaign_agreement: member.campaign_agreement,
            });
        });
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    });
    var onSubmit = function (data) {
        updateMemberApi(data);
    };
    var updateMemberApi = function (data) {
        var requestMember = {
            city: data.city,
            address: data.address,
            county: data.county,
            sms_notification: data.sms_notification,
            push_notification: data.push_notification,
            mail_notification: data.mail_notification,
            call_notification: data.call_notification,
            campaign_agreement: data.campaign_agreement,
            token: token
        };
        var requestSosyoleyMember = {
            favorite_team: data.favorite_team,
            share_option: data.share_option,
            token: token,
        };
        try {
            AccountTransactionApi.updateMember(requestMember).then(function (result) {
                if (result.status) {
                    modalServiceSubject.next({
                        title: "Güncellendi!",
                        content: "Kayıt başarıyla güncellenmiştir.",
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
                else {
                    modalServiceSubject.next({
                        title: "Uyarı!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            });
            AccountTransactionApi.uptadeSosyoleyMember(requestSosyoleyMember).then(function (result) {
                if (result.status) {
                }
            });
        }
        catch (_a) {
            modalServiceSubject.next({
                title: "Uyarı!",
                content: "Beklenmedik bir hata oluştu.",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    };
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, _c = _b.formState, reset = _b.reset;
    return (_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u00DCyelik Bilgilerim" }, void 0) }), void 0), _jsxs("div", __assign({ className: "card-body" }, { children: [_jsx(EmailUpdate, {}, void 0), _jsx(MobileUpdate, {}, void 0), _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "table row " }, { children: [_jsxs("div", __assign({ className: "col-md-4 col-sm-12 mt-3" }, { children: [_jsx("label", { children: "\u0130l" }, void 0), _jsx("input", __assign({ className: "form-control", type: "text", defaultValue: member.city }, register('city')), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-md-4 col-sm-12 mt-3" }, { children: [_jsx("label", { children: "\u0130l\u00E7e" }, void 0), _jsx("input", __assign({ className: "form-control", type: "text", defaultValue: member.county }, register('county')), void 0)] }), void 0), _jsxs("div", __assign({ className: "col-md-4 col-sm-12 mt-3" }, { children: [_jsx("label", { children: "Adres" }, void 0), _jsx("input", __assign({ className: "form-control", type: "text", defaultValue: member.address }, register('address')), void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "table row mt-3" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", { children: "Tak\u0131m" }, void 0), _jsx("div", { children: _jsxs("select", __assign({ className: "cursor-pointer" }, register('favorite_team'), { children: [_jsx("option", __assign({ value: member.favorite_team_id }, { children: teamsList.filter(function (team) { return (team.id === member.favorite_team_id); })[0] ? teamsList.filter(function (team) { return (team.id === member.favorite_team_id); })[0].name : null }), void 0), _jsx("option", __assign({ value: 0 }, { children: "Belirtmek \u0130stemiyorum" }), void 0), teamsList ? teamsList.map(function (team, key) {
                                                        return (_jsx("option", __assign({ value: team.id }, { children: team.name }), "sosyoleyOption" + key));
                                                    }) : null] }), void 0) }, void 0)] }), void 0) }), void 0), _jsx("div", __assign({ className: "table row mt-3" }, { children: _jsxs("div", __assign({ className: "col-12" }, { children: [_jsx("label", { children: "Referans Linki" }, void 0), _jsxs("div", __assign({ className: "form-control", onClick: function () {
                                                navigator.clipboard.writeText("https://xx.com/kayit?referer=" + member.id.toString(16).toUpperCase());
                                                document.execCommand('copy', true, "https://xx.com/kayit?referer=" + member.id.toString(16).toUpperCase());
                                                modalServiceSubject.next({
                                                    title: 'Referans linki kopyalandı',
                                                    content: "https://xx.com/kayit?referer=" + member.id.toString(16).toUpperCase(),
                                                    timeout: 3000,
                                                    confirm: {
                                                        cancel: {
                                                            label: 'Tamam'
                                                        }
                                                    }
                                                });
                                            } }, { children: ["https://xx.com/kayit?referer=", member.id.toString(16).toUpperCase(), _jsx("i", __assign({ className: "ml-2" }, { children: _jsx("img", { title: "Kopyala", src: CopyIcon, width: 16 }, void 0) }), void 0)] }), void 0)] }), void 0) }), void 0), _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit", value: "Kaydet" }, void 0)] }), void 0)] }), void 0), _jsx("div", { className: "card-footer" }, void 0)] }), void 0));
}
