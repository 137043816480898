var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
export default function Menu() {
    var openPage = window.location.href;
    return (_jsxs("ul", __assign({ className: "account-menu" }, { children: [_jsx("li", { children: _jsx("span", { children: "Hesap \u0130\u015Flemleri" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "uyelik", className: openPage.includes("hesabim/uyelik") ? "active" : '' }, { children: "\u00DCyelik Bilgilerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "guvenlik", className: openPage.includes("hesabim/guvenlik") ? "active" : "" }, { children: "\u015Eifre De\u011Fi\u015Ftir" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "hesap-hareketlerim", className: openPage.includes("hesabim/hesap-hareketlerim") ? "active" : "" }, { children: "Hesap Hareketlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx("span", { children: "Kuponlar\u0131m" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "iddaa/devam-edenler", className: openPage.includes("hesabim/iddaa") ? "active" : "" }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx("span", { children: "Finans \u0130\u015Flemleri" }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "para-yatir", className: openPage.includes("hesabim/para-yatir") ? "active" : "" }, { children: "Para Yat\u0131r" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "para-cek", className: openPage.includes("hesabim/para-cek") ? "active" : "" }, { children: "Para \u00C7ek" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "banka-hesaplarim", className: openPage.includes("hesabim/banka-hesaplarim") ? "active" : "" }, { children: "Banka Hesaplar\u0131m" }), void 0) }, void 0)] }), void 0));
}
