var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState, useMemo } from 'react';
import { depositMoneyList } from "../../../../store/account.store";
import { FinancalTransactionsApi } from "./financial.transactions.api";
import OtherBanks from "../../widgets/depositmoney/other.banks";
import { initialLeftMenu, sportsMenu } from "../../../../lib/misc.functions";
export default function DepositMoney() {
    var _a = useState(depositMoneyList), depositMoneyData = _a[0], setDepositMoneyData = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useMemo(function () {
        FinancalTransactionsApi.depositMoney().then(function (result) {
            setDepositMoneyData(result);
        });
    }, []);
    return (_jsxs("div", __assign({ className: "depositMoney" }, { children: [_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "Di\u011Fer Bankalar" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx(OtherBanks, {}, void 0) }), void 0)] }), void 0), _jsx("br", {}, void 0), _jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "S\u0131k\u00E7a Sorulan Sorular" }, void 0) }), void 0), _jsx("div", { className: "card-body" }, void 0)] }), void 0), _jsx("br", {}, void 0)] }), void 0));
}
