var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Android from "./subpages/android";
import Iphone from "./subpages/iphone";
// import MobileWeb from "./subpages/mobile.web";
import { useEffect } from 'react';
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
var MobilPlatform = function () {
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsxs("div", __assign({ className: "card" }, { children: [_jsx("div", __assign({ className: "card-title border" }, { children: _jsx("h2", { children: "Mobil Platformlar" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsxs(Tabs, { children: [_jsxs(TabList, { children: [_jsx(Tab, { children: "Android" }, void 0), _jsx(Tab, { children: "Iphone" }, void 0), _jsx(Tab, { children: "Mobile Web" }, void 0)] }, void 0), _jsx(TabPanel, { children: _jsx(Android, {}, void 0) }, void 0), _jsx(TabPanel, { children: _jsx(Iphone, {}, void 0) }, void 0)] }, void 0) }), void 0)] }), void 0));
};
export default MobilPlatform;
