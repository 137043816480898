var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import "./header.css";
import Login from "../widgets/login";
import { useAuthContext, member } from "../../store/login.store";
// import logo from '../../assets/img/oley-logo-blue.png';
import Navbar from "./components/navbar/navbar";
import Profile from "./components/profile/profile";
import { Link } from "react-router-dom";
import AlignLeft from "../../assets/feather/align-left.svg";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import { setThemeMode, toggleMobileSideMenu } from "../../lib/misc.functions";
import { eventDetailSubject } from "../../store/sportsbook.store";
import { Storage } from "../../lib/localstorege.service";
import { isMobileView } from "../../app";
// @ts-ignore
// const CounterAppOne = React.lazy(() => import("app1/CounterAppOne"));
export default function Header() {
    var isAuthenticated = useAuthContext().isAuthenticated;
    var defaultDark = Storage.get('data-theme') || 'light';
    var _a = useState(defaultDark), theme = _a[0], setTheme = _a[1];
    // console.log("theme>>>",theme)
    useEffect(function () {
        setThemeMode(theme);
    }, []);
    var updateTheme = function (theme) {
        setTheme(theme);
        setThemeMode(theme);
    };
    return (_jsx("div", __assign({ className: "header-wrapper" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-lg-2 col-md-2 col-sm-6 mb-0 mt-md-3 mt-8 pr-md-0 px-4 d-flex justify-content-between" }, { children: [_jsx("div", __assign({ className: "float-left left-menu-toggle", onClick: function () {
                                betSlipVisibleSubject.next(false);
                                eventDetailSubject.next('toggle');
                                toggleMobileSideMenu();
                            } }, { children: _jsx("img", { className: "ico", src: AlignLeft, alt: "" }, void 0) }), void 0), _jsx(Link, __assign({ to: "/", onClick: function () {
                                betSlipVisibleSubject.next(false);
                            } }, { children: "LOGO" }), void 0), !isMobileView && _jsxs("div", __assign({ id: "btnDark", style: { fontSize: '11px', lineHeight: '26px', color: 'var(--color-text-header)' }, className: "" }, { children: [theme === 'dark' ? _jsxs("div", __assign({ className: "float-left ml-2", onClick: function () {
                                        updateTheme('light');
                                    } }, { children: [_jsx("div", __assign({ className: "float-left mr-2 ml-4 visible-sm" }, { children: "Dark Mode:" }), void 0), _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 32 32", "data-name": "trade_sun", fill: "currentColor", className: "svgIcon__caad1", style: { width: '18px', height: '18px' } }, { children: _jsx("path", { d: "M16 5.978C10.466 5.978 5.978 10.467 5.978 16S10.467 26.022 16 26.022c5.534 0 10.022-4.489 10.022-10.022S21.533 5.978 16 5.978zm5.82 15.842a8.228 8.228 0 01-2.617 1.765c-1.013.43-2.09.648-3.204.648s-2.191-.218-3.204-.648c-.981-.415-1.861-1.009-2.617-1.765a8.223 8.223 0 010-11.642 8.223 8.223 0 0111.642 0 8.223 8.223 0 010 11.642zM16.895 3.583h-1.79V.004h1.79v3.579zm-1.79 24.834h1.79v3.579h-1.79v-3.579zM6.586 7.853L4.055 5.322l1.267-1.267 2.531 2.531-1.267 1.267zm18.828 16.294l2.531 2.531-1.267 1.267-2.531-2.531 1.267-1.267zM.004 15.105h3.579v1.79H.004v-1.79zm31.992 0v1.79h-3.579v-1.79h3.579zm-25.41 9.042l1.267 1.267-2.531 2.531-1.267-1.267 2.531-2.531zM26.677 4.055l1.267 1.267-2.531 2.531-1.267-1.267 2.531-2.531z" }, void 0) }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0), theme === 'light' ? _jsxs("div", __assign({ className: "float-left  ml-2", onClick: function () {
                                        updateTheme('dark');
                                    } }, { children: [_jsx("div", __assign({ className: "float-left mr-2 ml-4 visible-sm" }, { children: "Dark Mode:" }), void 0), _jsx("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", width: "32", height: "32", viewBox: "0 0 32 32", "data-name": "trade_moon", fill: "currentColor", className: "svgIcon__caad1", style: { width: '18px', height: '18px' } }, { children: _jsx("path", { d: "M14 10.6c0-3.533 1.267-6.733 3.333-9.267C9.866 2 4 8.333 4 16a14.625 14.625 0 0014.667 14.667c4.6 0 9.333-2.133 12-5.4-.467.067-1.533.067-2 .067-8.133 0-14.667-6.6-14.667-14.733zm4.667 18.733c-7.333 0-13.333-6-13.333-13.333 0-5.867 3.733-10.933 9.133-12.667-1.133 2.267-1.8 4.8-1.8 7.333 0 8.4 6.4 15.267 14.6 16-2.4 1.667-5.533 2.667-8.6 2.667z" }, void 0) }), void 0)] }), void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0), _jsx("div", __assign({ className: "col-lg-8 col-md-8 navbar-col mb-0 mt-0" }, { children: _jsx(Navbar, {}, void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-2 col-md-2 col-sm-6 profile-column mb-0 d-flex justify-content-end align-items-center" }, { children: isAuthenticated ? _jsx(Profile, { member: member }, void 0) : _jsx(Login, {}, void 0) }), void 0)] }), void 0) }), void 0));
}
