var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { Storage } from "../../../../lib/localstorege.service";
import { customStyles } from "../../../../definitions/constants/modal.constant";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { GameOperationsApi } from '../../subpages/gameOperations/game.operations.api';
function CouponRemove(props) {
    var token = Storage.get('token');
    var _a = React.useState(false), modalIsOpenRemove = _a[0], setIsOpenRemove = _a[1];
    function closeModal() {
        setIsOpenRemove(false);
    }
    function openModalRemove() {
        setIsOpenRemove(true);
    }
    var _b = useForm(), handleSubmit = _b.handleSubmit, _c = _b.formState;
    var onSubmit = function (data) {
        data.coupon_id = props.coupon.id;
        data.token = token;
        couponRemove(data);
    };
    var couponRemove = function (data) {
        if (navigator.onLine) {
            GameOperationsApi.removeCoupon(data).then(function (result) {
                if (result.status) {
                    closeModal();
                    props.closeModal();
                    var element = document.getElementById(props.coupon.id);
                    element.style.display = "none";
                }
                else {
                    modalServiceSubject.next({
                        title: "Uyarı!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            }).catch(function (error) {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: error,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            });
        }
        else {
            modalServiceSubject.next({
                title: "Uyarı!",
                content: "İnternet bağlantınızı kontrol ediniz.",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "text-right social-share" }, { children: _jsx("button", __assign({ onClick: function () { return openModalRemove(); }, className: "btn btn-small btn-danger" }, { children: "Kuponu Sil" }), void 0) }), void 0), _jsx(Modal, __assign({ isOpen: modalIsOpenRemove, onRequestClose: closeModal, style: customStyles, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "page card" }, { children: [_jsx("div", __assign({ className: "card-title d-flex justify-content-center" }, { children: _jsx("h3", { children: "Emin misiniz?" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body d-flex justify-content-center" }, { children: _jsx("div", __assign({ className: "table row  d-flex justify-content-center" }, { children: _jsx("div", __assign({ className: "col-12" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("div", { children: _jsx("span", { children: "Kuponu silmek istedi\u011Finizden emin misiniz?" }, void 0) }, void 0), _jsxs("div", __assign({ className: "d-flex justify-content-center" }, { children: [_jsx("button", __assign({ onClick: function () { return closeModal(); }, className: "btn btn-primary btn-large mt-3" }, { children: "Hay\u0131r" }), void 0), _jsx("input", { className: "btn btn-primary btn-large mt-3", type: "submit", value: "Evet" }, void 0)] }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
}
export default CouponRemove;
