var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import SporTotoLogo from '../../../assets/img/sportoto_siyah.png';
import IddiaLogo from '../../../assets/img/iddaa-logo.png';
import { Link } from "react-router-dom";
function Logos() {
    return (_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-3" }, { children: _jsx(Link, __assign({ to: "/iddaa/futbol/tumu" }, { children: _jsx("img", { className: "iddaa-logo", src: IddiaLogo, alt: "\u0130ddaa" }, void 0) }), void 0) }), void 0), _jsx("div", { className: "col-3" }, void 0)] }), void 0));
}
export default Logos;
