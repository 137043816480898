import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import MembershipInformation from "../subpages/accountTransactions/membership.information";
import ChangePassword from "../subpages/accountTransactions/change.password";
import AccountTransactions from "../subpages/accountTransactions/account.transactions";
import DepositMoney from "../subpages/financialTransactions/deposit.money";
import WithdrawMoney from "../subpages/financialTransactions/withdraw.money";
import MyBankAccounts from "../subpages/financialTransactions/my.bank.accounts";
import Iddia from "../subpages/gameOperations/iddia";
import SupportRequests from "../subpages/support/support.requests";
import { useNavigate } from "react-router-dom";
import SupportDetail from '../subpages/support/support.detail';
export default function SubPageSelected(props) {
    var navigate = useNavigate();
    var subPagesRouter = {
        'uyelik': _jsx(MembershipInformation, {}, void 0),
        'guvenlik': _jsx(ChangePassword, {}, void 0),
        'hesap-hareketlerim': _jsx(AccountTransactions, { params: props.params }, void 0),
        'para-yatir': _jsx(DepositMoney, {}, void 0),
        'para-cek': _jsx(WithdrawMoney, {}, void 0),
        'banka-hesaplarim': _jsx(MyBankAccounts, {}, void 0),
        'iddaa': _jsx(Iddia, { params: props.params }, void 0),
        'destek-taleplerim': _jsx(SupportRequests, { params: props.params }, void 0),
        'destek-taleplerim-detay': _jsx(SupportDetail, {}, void 0),
    };
    useEffect(function () {
        if (!subPagesRouter.hasOwnProperty(props.subpage)) {
            navigate("/");
        }
    });
    if (subPagesRouter.hasOwnProperty(props.subpage)) {
        return subPagesRouter[props.subpage];
    }
    return _jsx(_Fragment, {}, void 0);
}
