var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { loginSubject, useAuthContext } from "../../store/login.store";
import { useForm } from "react-hook-form";
import { callLoginApi } from "../../services/login.services";
import { useNavigate } from "react-router-dom";
import paths from "../../app/paths";
import '../custom.css';
import { location } from "../../store/login.store";
import { initialLeftMenu, sportsMenu } from "../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import EyeOffIcon from "../../assets/feather/eye-off.svg";
import EyeIcon from "../../assets/feather/eye.svg";
var LoginPage = function () {
    var navigate = useNavigate();
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit, errors = _b.formState.errors;
    var onSubmit = function (data) {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = true;
        }
        callLoginApi(data.username, data.password);
    };
    useEffect(function () {
        var loginButton = document.getElementById('id_btnLogin');
        if (loginButton) {
            loginButton.disabled = false;
        }
        betSlipVisibleSubject.next(false);
    }, []);
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function (member) {
            if (member.id !== undefined && member.status === 1) {
                setIsAuthenticated(true);
                var pathname = window.location.pathname.slice(1);
                if (pathname === paths.LOGIN || pathname === '') {
                    pathname = '/';
                }
                if (location.path) {
                    if (location.path == "back") {
                        window.history.back();
                    }
                    else {
                        navigate(location.path);
                    }
                }
                else {
                    navigate('/');
                }
            }
        });
        if (isAuthenticated) {
            var pathname = window.location.pathname.slice(1);
            if (pathname === paths.LOGIN || pathname === '') {
                pathname = '/';
            }
            if (location.path) {
                if (location.path == "back") {
                    window.history.back();
                }
                else {
                    navigate(location.path);
                }
            }
            else {
                navigate('/');
            }
        }
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    }, []);
    var isPasswordVisible = function () {
        var password = document.getElementById("password");
        var eyeIcon = document.getElementById("eye-icon");
        if (password.type === "password") {
            password.type = "text";
            eyeIcon.src = EyeIcon;
        }
        else {
            password.type = "password";
            eyeIcon.src = EyeOffIcon;
        }
    };
    return (_jsx("div", __assign({ className: "container page login " }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", { className: "col" }, void 0), _jsxs("div", __assign({ className: "card col mt-5" }, { children: [_jsx("div", __assign({ className: "card-title" }, { children: _jsx("h2", { children: "\u00DCye Giri\u015Fi" }, void 0) }), void 0), _jsx("div", __assign({ className: "card-body" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-lg-12 col-sm-12" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u00DCye No / TC No / E-posta" }, void 0), _jsx("input", __assign({ className: "form-control" }, register('username', { required: true })), void 0), errors.username &&
                                                        _jsx("div", __assign({ className: "text-validation" }, { children: "\u00DCye No / TC No / E-Posta gereklidir." }), void 0)] }), void 0), _jsxs("div", __assign({ className: "mt-2" }, { children: [_jsx("label", { children: "\u015Eifre" }, void 0), _jsx("input", __assign({ type: "password", className: "form-control", id: "password" }, register('password', { required: true })), void 0), errors.password &&
                                                        _jsx("div", __assign({ className: "text-validation" }, { children: "\u015Eifre gereklidir." }), void 0), _jsx("div", __assign({ className: "cursor-pointer eye-icon float-right mr-2 position-absolute", onClick: function () { return isPasswordVisible(); } }, { children: _jsx("img", { id: "eye-icon", className: "eye-icon", height: 16, width: 16, src: EyeOffIcon }, void 0) }), void 0)] }), void 0), _jsx("div", __assign({ className: "mt-2" }, { children: _jsx("input", { type: "submit", id: "id_btnLogin", className: "btn btn-primary mt-2", value: "Giri\u015F Yap" }, void 0) }), void 0)] }), void 0) }), void 0) }), void 0) }), void 0)] }), void 0), _jsx("div", { className: "col" }, void 0)] }), void 0) }), void 0));
};
export default LoginPage;
