var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import IconBell from '../../../../assets/feather/bell.svg';
import logOutSvg from '../../../../assets/feather/log-out.svg';
import Copy from '../../../../assets/feather/copy.svg';
import { loginSubject, useAuthContext } from "../../../../store/login.store";
import { doLogout } from "../../../../services/login.services";
import { Link, useNavigate } from "react-router-dom";
import paths from "../../../../app/paths";
import { useForceUpdate } from "../../../../lib/misc.functions";
import { betSlipVisibleSubject } from "../../../../store/betslip.store";
function Profile(probs) {
    var openPage = window.location.href;
    var navigate = useNavigate();
    var forceUpdate = useForceUpdate();
    var _a = useAuthContext(), isAuthenticated = _a.isAuthenticated, setIsAuthenticated = _a.setIsAuthenticated;
    var member = probs.member;
    if (!isAuthenticated) {
        return _jsx(_Fragment, {}, void 0);
    }
    var logout = function () {
        doLogout();
        var pathname = window.location.pathname.slice(1);
        if (pathname === paths.LOGIN || pathname === '') {
            pathname = '/';
        }
        setIsAuthenticated(false);
        betSlipVisibleSubject.next(false);
        navigate('/giris-yap');
    };
    useEffect(function () {
        var loginSubject_subscription = loginSubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            loginSubject_subscription.unsubscribe();
        };
    });
    var copyMemberId = function () {
        var element = document.getElementById("copy");
        navigator.clipboard.writeText(member.id);
        element.style.display = "inline";
        element.classList.add("elementToFadeInAndOut");
    };
    function menuSelected(status) {
        var menu = document.getElementById("profile-menu");
        if (status) {
            menu.style.display = "block";
        }
        else {
            menu.style.display = "none";
        }
    }
    if (innerWidth < 968) {
        return (_jsxs("div", __assign({ className: "profile float-right pr-3" }, { children: [_jsxs("div", __assign({ className: "member-info", onMouseOver: function () { return menuSelected(true); }, onMouseOut: function () { return menuSelected(false); } }, { children: [_jsxs("div", { children: [_jsx("span", __assign({ id: "copy", className: "copy-tooltip-navbar " }, { children: "Kopyaland\u0131" }), void 0), "\u00DCye No: ", member.id, " \u00A0", _jsx("img", { src: Copy, alt: "copy", style: { filter: 'invert(1)' }, className: "cursor-pointer", id: "copy-icon", onClick: function () { return copyMemberId(); } }, void 0)] }, void 0), _jsxs("div", __assign({ className: "balance", style: { color: '#FFFFFF' } }, { children: [_jsx("span", { children: "Bakiye:" }, void 0), " ", member.getAvailableBalance().toFixed(2), "\u20BA"] }), void 0), _jsxs("ul", __assign({ className: 'profile-ul', id: "profile-menu", onClick: function () { return menuSelected(false); } }, { children: [_jsx("li", { children: _jsxs("div", { children: ["TL Bakiye ", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [parseFloat(member.debit_balance).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/uyelik", className: openPage.includes("hesabim/uyelik") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "\u00DCyelik Bilgilerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-yatir", className: openPage.includes("hesabim/para-yatir") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Para Yat\u0131r" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-cek", className: openPage.includes("hesabim/para-cek") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Para \u00C7ek" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/iddaa/devam-edenler", className: openPage.includes("hesabim/iddaa/devam-edenler") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/banka-hesaplarim", className: openPage.includes("hesabim/banka-hesaplarim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Banka Hesaplar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/hesap-hareketlerim", className: openPage.includes("hesabim/hesap-hareketlerim") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "Hesap Hareketlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/guvenlik", className: openPage.includes("hesabim/guvenlik") ? "active" : "", onClick: function () {
                                            betSlipVisibleSubject.next(false);
                                        } }, { children: "\u015Eifre De\u011Fi\u015Ftir" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                            logout();
                                        }, style: { cursor: "pointer" } }, { children: "\u00C7\u0131k\u0131\u015F Yap" }), void 0) }, void 0)] }), void 0)] }), void 0), member && member.notication_count && Number(member.notication_count) > 0 ? _jsxs("div", __assign({ className: "notification" }, { children: [_jsx("div", __assign({ onClick: function () {
                                navigate('/bildirimler');
                            }, className: "badge cursor-pointer" }, { children: String(member.notication_count) }), void 0), _jsx(Link, __assign({ to: "/bildirimler" }, { children: _jsx("img", { src: IconBell, style: { filter: 'invert(1)', width: 20 }, className: "IconBell" }, void 0) }), void 0)] }), void 0) : null, _jsx("div", __assign({ className: "signout" }, { children: _jsx(Link, __assign({ to: "/", onClick: function () {
                            logout();
                        } }, { children: _jsx("img", { src: logOutSvg, style: { filter: 'invert(1)', width: 20 }, className: "IconBell" }, void 0) }), void 0) }), void 0)] }), void 0));
    }
    return (_jsxs("div", __assign({ className: "profile float-right pr-3" }, { children: [_jsxs("div", __assign({ className: "member-info", onMouseOver: function () { return menuSelected(true); }, onMouseOut: function () { return menuSelected(false); } }, { children: [_jsxs("div", __assign({ style: { color: 'var(--color-text-dark)' } }, { children: [_jsx("span", __assign({ id: "copy", className: "copy-tooltip-navbar " }, { children: "Kopyaland\u0131" }), void 0), "\u00DCye No: ", member.id, " \u00A0", _jsx("img", { src: Copy, alt: "copy", className: "cursor-pointer invert-color", id: "copy-icon", onClick: function () { return copyMemberId(); } }, void 0)] }), void 0), _jsxs("div", __assign({ className: "balance" }, { children: [_jsx("span", { children: "Bakiye:" }, void 0), " ", member.getAvailableBalance().toFixed(2), "\u20BA"] }), void 0), _jsxs("ul", __assign({ className: 'profile-ul', id: "profile-menu", onClick: function () { return menuSelected(false); } }, { children: [_jsx("li", { children: _jsxs("div", { children: ["TL Bakiye ", _jsxs("span", __assign({ className: "float-right fw-bold" }, { children: [parseFloat(member.debit_balance).toFixed(2), " \u20BA"] }), void 0)] }, void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/uyelik", className: openPage.includes("hesabim/uyelik") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "\u00DCyelik Bilgilerim" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-yatir", className: openPage.includes("hesabim/para-yatir") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Para Yat\u0131r" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/para-cek", className: openPage.includes("hesabim/para-cek") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Para \u00C7ek" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/iddaa/devam-edenler", className: openPage.includes("hesabim/iddaa/devam-edenler") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/banka-hesaplarim", className: openPage.includes("hesabim/banka-hesaplarim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Banka Hesaplar\u0131m" }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "/hesabim/hesap-hareketlerim", className: openPage.includes("hesabim/hesap-hareketlerim") ? "active" : "", onClick: function () {
                                        betSlipVisibleSubject.next(false);
                                    } }, { children: "Hesap Hareketlerim" }), void 0) }, void 0), _jsx("li", { children: _jsx("a", __assign({ onClick: function () {
                                        logout();
                                    }, style: { cursor: "pointer" } }, { children: "\u00C7\u0131k\u0131\u015F Yap" }), void 0) }, void 0)] }), void 0)] }), void 0), member && member.notication_count && Number(member.notication_count) > 0 ? _jsxs("div", __assign({ className: "notification" }, { children: [_jsx("div", __assign({ onClick: function () {
                            navigate('/bildirimler');
                        }, className: "badge cursor-pointer" }, { children: String(member.notication_count) }), void 0), _jsx(Link, __assign({ to: "/bildirimler" }, { children: _jsx("img", { src: IconBell, className: "IconBell invert-color" }, void 0) }), void 0)] }), void 0) : null, _jsx("div", __assign({ className: "signout" }, { children: _jsx(Link, __assign({ to: "/", onClick: function () {
                        logout();
                    } }, { children: _jsx("img", { src: logOutSvg, style: { filter: 'invert(1)', width: 20 }, className: "IconBell" }, void 0) }), void 0) }), void 0)] }), void 0));
}
export default Profile;
