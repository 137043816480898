var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { Storage } from "../../../../lib/localstorege.service";
import { Api } from "./account.transactions.api";
import { currencyFormat, dateTimeFormat, initialLeftMenu, sportsMenu, useForceUpdate } from "../../../../lib/misc.functions";
import { env, TransactionTypeLabel, UnitLabel } from "../../../../definitions/global.vars";
import { modalServiceSubject } from "../../../../components/modal/modal";
var page = 0;
var hasMore = true;
var AccountTransactions = function (props) {
    var _a = useState(-1), accountTransactionsStatus = _a[0], setAccountTransactionsStatus = _a[1];
    useEffect(function () {
        initialLeftMenu(false);
    }, [sportsMenu.isVisible]);
    return (_jsx(_Fragment, { children: _jsxs("div", __assign({ className: "card" }, { children: [_jsxs("div", __assign({ className: "card-title" }, { children: [_jsx("h2", { children: "Hesap Hareketlerim" }, void 0), _jsx("div", __assign({ className: "actions" }, { children: _jsxs("select", __assign({ onChange: function (e) { return setAccountTransactionsStatus(e.target.value); } }, { children: [_jsx("option", __assign({ value: -1 }, { children: "T\u00FCm\u00FC" }), void 0), _jsxs("optgroup", __assign({ label: "Para \u0130\u015Flemleri" }, { children: [_jsx("option", __assign({ value: 0 }, { children: "Devir" }), void 0), _jsx("option", __assign({ value: 1 }, { children: "Para Yat\u0131rma" }), void 0), _jsx("option", __assign({ value: 2 }, { children: "Para Yat\u0131rma \u0130adesi" }), void 0), _jsx("option", __assign({ value: 3 }, { children: "Para \u00C7ekme" }), void 0), _jsx("option", __assign({ value: 4 }, { children: "Para \u00C7ekme \u0130adesi" }), void 0)] }), void 0), _jsxs("optgroup", __assign({ label: "\u0130ddaa" }, { children: [_jsx("option", __assign({ value: 5 }, { children: "Kupon Oynama" }), void 0), _jsx("option", __assign({ value: 6 }, { children: "Kupon \u0130adesi" }), void 0), _jsx("option", __assign({ value: 7 }, { children: "Kupon Kazan\u00E7" }), void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0), _jsx("div", __assign({ id: "InfiniteScroll", className: "body p-0 " }, { children: _jsx(ListCoupons, { accountTransactionsStatus: accountTransactionsStatus, params: props.params }, void 0) }), void 0)] }), void 0) }, void 0));
};
export default AccountTransactions;
var accountTransactionData = [];
var lockScroll = false;
var accountTransactionStatus = -1;
var timer;
export function ListCoupons(props) {
    var forceUpdate = useForceUpdate();
    var params = props;
    useEffect(function () {
        window.scrollTo(0, 0);
        accountTransactionData = [];
        fetchData(true);
    }, [params.accountTransactionsStatus, props.params.subpage]);
    // @ts-ignore
    accountTransactionStatus = params.accountTransactionsStatus;
    var fetchData = function (isFirst, forcePage, top) {
        if (isFirst === void 0) { isFirst = false; }
        if (forcePage === void 0) { forcePage = null; }
        if (top === void 0) { top = null; }
        try {
            // @ts-ignore
            document.getElementById('loadingAnimation').style.display = 'block';
            hasMore = false;
            if (isFirst) {
                accountTransactionData = [];
                page = forcePage !== null ? forcePage : 0;
                hasMore = true;
            }
            var data = {
                token: Storage.get('token'),
                page: page
            };
            if (Number(accountTransactionStatus) !== -1) {
                data['trans_type'] = accountTransactionStatus;
            }
            // @ts-ignore
            var objectHeight_1 = document.getElementById('InfiniteScroll').offsetHeight;
            // @ts-ignore
            var objectTop_1 = document.getElementById('InfiniteScroll').offsetTop;
            Api.start('post', env.list_api + '/accounting/api/transactions/', data, true).then(function (result) {
                // @ts-ignore
                document.getElementById('loadingAnimation').style.display = 'none';
                if (result.status) {
                    if (result.data.length < 20) {
                        hasMore = false;
                        lockScroll = true;
                    }
                    else {
                        hasMore = true;
                    }
                    page += 1;
                    accountTransactionData = accountTransactionData.concat(result.data);
                    forceUpdate();
                    if (!isFirst) {
                        if (top === 0) {
                            window.scrollTo(0, top);
                        }
                        else {
                            var threshold = window.outerHeight < 700 ? 600 : 700;
                            window.scrollTo(0, (objectHeight_1 - objectTop_1 - threshold));
                        }
                    }
                    timer = setTimeout(function () {
                        if (result.data.length >= 20) {
                            lockScroll = false;
                            if (accountTransactionData.length === 20 && objectHeight_1 < window.outerHeight) {
                                fetchData(false, 1, 0);
                            }
                        }
                        triggerScroll();
                    }, 500);
                }
                else {
                    modalServiceSubject.next({
                        title: 'beklenmedik bir sorun',
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 3000,
                    });
                }
            });
        }
        catch (e) {
        }
    };
    useEffect(function () {
        window.addEventListener('scroll', triggerScroll);
        return function () {
            clearTimeout(timer);
            window.removeEventListener('scroll', triggerScroll);
        };
    }, []);
    var triggerScroll = function () {
        var threshold = window.outerHeight < 968 ? 100 : 150;
        // @ts-ignore
        var objectHeight = document.getElementById('InfiniteScroll').offsetHeight;
        // @ts-ignore
        var objectTop = document.getElementById('InfiniteScroll').offsetTop;
        var visibleZone = (window.outerHeight - objectTop - threshold);
        if (objectHeight > visibleZone) {
            if ((objectHeight - visibleZone) < window.scrollY && !lockScroll) {
                lockScroll = true;
                fetchData(false);
            }
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("table", __assign({ className: "table" }, { children: [_jsx(Title, {}, void 0), _jsx("tbody", { children: accountTransactionData.map(function (data, key) {
                            return (_jsxs("tr", { children: [_jsx("td", __assign({ className: "hidden-sm text-center" }, { children: dateTimeFormat(data.transaction_date) }), void 0), _jsx("td", __assign({ className: "hidden-sm text-center" }, { children: TransactionTypeLabel[data.trans_type] }), void 0), _jsxs("td", __assign({ className: "text-left" }, { children: [_jsx("div", __assign({ className: "visible-sm fw-bold" }, { children: dateTimeFormat(data.transaction_date) }), void 0), _jsxs("div", __assign({ className: "visible-sm" }, { children: [TransactionTypeLabel[data.trans_type], " ", data.description] }), void 0), _jsx("div", __assign({ className: "hidden-sm" }, { children: data.description }), void 0)] }), void 0), _jsx("td", __assign({ className: "text-right" }, { children: _jsxs("span", __assign({ className: "nowrap " + (Number(data.io_type) === 0 ? 'nagative-text' : 'positive-text') }, { children: [currencyFormat(Number(data.amount)), " ", UnitLabel[Number(data.unit)]] }), void 0) }), void 0)] }, "accountTransactions" + key));
                        }) }, void 0)] }), void 0), hasMore == false && accountTransactionData.length === 0 ? _jsx(_Fragment, { children: _jsx("p", __assign({ className: "text-center py-5" }, { children: "Listelenecek kuponunuz yoktur." }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", __assign({ id: "loadingAnimation", className: "text-center py-5" }, { children: _jsxs("div", __assign({ className: "lds-roller", style: { position: 'unset' } }, { children: [_jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0), _jsx("div", {}, void 0)] }), void 0) }), void 0)] }, void 0));
}
var Title = function () {
    return (_jsx("thead", { children: _jsxs("tr", { children: [_jsx("th", __assign({ className: "hidden-sm text-center" }, { children: "Tarih" }), void 0), _jsx("th", __assign({ className: "hidden-sm text-center" }, { children: "\u0130\u015Flem" }), void 0), _jsx("th", { children: "A\u00E7\u0131klama" }, void 0), _jsx("th", __assign({ className: "text-center" }, { children: "Tutar" }), void 0)] }, void 0) }, void 0));
};
