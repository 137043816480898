var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useAuthContext } from "../../../../store/login.store";
import { setThemeMode, useForceUpdate } from "../../../../lib/misc.functions";
import { Storage } from "../../../../lib/localstorege.service";
export default function Navbar() {
    var isAuthenticated = useAuthContext().isAuthenticated;
    var defaultDark = Storage.get('data-theme') || 'dark';
    var forceUpdate = useForceUpdate();
    var _a = useState(defaultDark), theme = _a[0], setTheme = _a[1];
    var currentPage = window.location.href;
    // console.log("theme>>>",theme)
    useEffect(function () {
        setThemeMode(theme);
    }, []);
    var updateTheme = function (theme) {
        setTheme(theme);
        setThemeMode(theme);
    };
    var navMenuSelector = function (e) {
        e.target.parentElement.style.display = 'none';
        setTimeout(function () {
            e.target.parentElement.style.display = null;
        }, 100);
        forceUpdate();
    };
    var rendered = function () {
        return (_jsx(_Fragment, { children: _jsxs("nav", __assign({ className: "row justify-content-center align-items-center" }, { children: [_jsx("div", __assign({ className: currentPage.includes("iddaa/tumu/canli") ? 'active hidden-sm' : 'hidden-sm' }, { children: _jsx(Link, __assign({ onClick: function (e) { navMenuSelector(e); }, to: "iddaa/tumu/canli" }, { children: "CANLI" }), void 0) }), void 0), _jsx("div", __assign({ className: currentPage.includes("iddaa/futbol/tumu") ? 'active hidden-sm' : 'hidden-sm' }, { children: _jsx(Link, __assign({ onClick: function (e) { navMenuSelector(e); }, to: "iddaa/futbol/tumu" }, { children: "SPOR" }), void 0) }), void 0), _jsx("div", __assign({ className: currentPage.includes("slot") ? 'active hidden-sm' : 'hidden-sm' }, { children: _jsx(Link, __assign({ onClick: function (e) { navMenuSelector(e); }, to: "slot" }, { children: "SLOT" }), void 0) }), void 0), _jsx("div", __assign({ className: currentPage.includes("casino") ? 'active hidden-sm' : 'hidden-sm' }, { children: _jsx(Link, __assign({ onClick: function (e) { navMenuSelector(e); }, to: "casino" }, { children: "CASINO" }), void 0) }), void 0)] }), void 0) }, void 0));
    };
    // const memorized = useMemo(() => rendered(), [isAuthenticated, theme]);
    return rendered();
}
