var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { eventUpdateSubject, popularCompetition, sportsbookList, eventSliderList, eventMapping, marketMapping, marketIdMapping, sportsbookFilter, highlightedEvents, sportsSummerySubject, sportsSummery, customCompetitionPriorty, statisticsMap, customCompetitionGroupNames } from "../store/sportsbook.store";
import { ApiService } from '../lib/http.service';
import { handicappedMarkets, highLightedMarketCategories } from "../definitions/constants/market-categories.constant";
import { env } from "../definitions/global.vars";
import { SportbookWS } from "./websocket.services";
import { Storage } from "../lib/localstorege.service";
import _ from "lodash";
import { BetSlipList } from "../store/betslip.store";
import { addToBetSlip, initializeBetSlip, restoreBetSlip } from "./betslip.services";
import { modalServiceSubject } from "../components/modal/modal";
import { eventEndedStatuses } from "../definitions/constants/event-result-type";
import { commentUpdateSubject, getMemberInfo } from "./sosyoley.services";
import { member } from "../store/login.store";
import { ServerDate } from "../lib/misc.functions";
import { eventSliderSubject } from "../pages/home/widgets/slider";
export var sportsbookApi = env.currentbook_api + "/sportsbook/?v=" + Math.random().toString();
// export const sportsbookApi = env.currentbook_api + "/current/?v=" + Math.random().toString()
export var myHighLightedMarketGroups = {};
function restoreHighLightedMarketGroups() {
    var savedList = Storage.get('highLightedMarketCategories');
    if (savedList) {
        var _loop_1 = function (s, v) {
            var aaa = savedList[s].map(function (m) { return m.pre; });
            myHighLightedMarketGroups[s] = highLightedMarketCategories[s].filter(function (el) { return aaa.includes(el.pre); });
        };
        // @ts-ignore
        for (var _i = 0, _a = Object.entries(savedList); _i < _a.length; _i++) {
            var _b = _a[_i], s = _b[0], v = _b[1];
            _loop_1(s, v);
        }
    }
    else {
        // @ts-ignore
        for (var _c = 0, _d = Object.entries(highLightedMarketCategories); _c < _d.length; _c++) {
            var _e = _d[_c], s = _e[0], v = _e[1];
            myHighLightedMarketGroups[s] = highLightedMarketCategories[s].slice(0, 3);
        }
    }
}
restoreHighLightedMarketGroups();
var sportsOrders = {
    'futbol_1': 1,
    'basketbol_1': 2,
    'tenis_1': 3,
    'duello_1': 4,
    'voleybol_1': 5,
    'buz-hokeyi_1': 6,
    'hentbol_1': 7,
    'snooker_1': 8,
    'mma_1': 9,
    'futbol_2': 10,
    'basketbol_2': 11,
    'tenis_2': 12,
    'duello_2': 13,
    'voleybol_2': 14,
    'buz-hokeyi_2': 15,
    'hentbol_2': 16,
    'snooker_2': 17,
    'mma_2': 18
};
export function getSportSummery() {
    return __awaiter(this, void 0, void 0, function () {
        var summer, summeryList, _loop_2, _i, _a, _b, s, v;
        return __generator(this, function (_c) {
            summer = _.groupBy(sportsbookList.filter(function (el) { return el.marketOutcomeCount > 0; }), function (item) {
                return "".concat(item.sportSlug, "___").concat(item.sportId, "___").concat(item.eventType, "___").concat(item.bettingPhase, "___").concat(item.sportName);
            });
            summeryList = [];
            _loop_2 = function (s, v) {
                // console.log(s.split('___'))
                var i = s.split('___');
                var isExist = summeryList.filter(function (el) { return el.sportSlug === i[0] && Number(el.eventType) === Number(i[2]); });
                if (isExist.length > 0) {
                    if (isExist[0].activeGame !== true && Number(i[3]) === Number(1)) {
                        isExist[0].activeGame = true;
                    }
                    isExist[0].count += v.length;
                    if (Number(i[3]) === Number(1)) {
                        isExist[0]["liveEventCount"] = v.length;
                    }
                }
                else {
                    summeryList.push({
                        activeGame: Number(i[3]) === Number(1),
                        count: v.length,
                        eventType: i[2],
                        isLiveEvent: Number(i[3]) === Number(1),
                        liveEventCount: Number(i[3]) === Number(1) ? v.length : 0,
                        order_id: sportsOrders["".concat(i[0], "_").concat(i[2])],
                        sportId: i[1],
                        sportName: i[4],
                        sportSlug: i[0],
                    });
                }
            };
            for (_i = 0, _a = Object.entries(summer); _i < _a.length; _i++) {
                _b = _a[_i], s = _b[0], v = _b[1];
                _loop_2(s, v);
            }
            summeryList = _.orderBy(summeryList, ['order_id']);
            Object.assign(sportsSummery, summeryList);
            sportsSummerySubject.next(true);
            return [2 /*return*/];
        });
    });
}
export function setHighLightedMarketGroups(markets, sportSlug) {
    var sortedMarkets = _.orderBy(markets, 'priority');
    myHighLightedMarketGroups[sportSlug] = sortedMarkets;
}
var YeniMarket = [
    '4-57'
];
var withoutSpecialOddValues = [
    '4-717'
];
var replaceSpecialOffValues = [
    '4-736'
];
export function marketNameTransform(outcomeName, specialOddsValue, market) {
    if (market === void 0) { market = null; }
    // console.log(market.marketName.toLowerCase())
    if (handicappedMarkets.includes(market.marketType + '-' + market.marketSubType)) {
        if (Number(specialOddsValue) < 0) {
            outcomeName = outcomeName + ' (0 : ' + (Number(specialOddsValue) * -1).toString() + ')';
        }
        else {
            if (specialOddsValue) {
                outcomeName = outcomeName + ' (' + specialOddsValue.toString() + ' : 0)';
            }
        }
        return outcomeName;
    }
    else if (market && YeniMarket.includes(market.marketType + '-' + market.marketSubType)) {
        return market.marketName + ' : ' + outcomeName;
    }
    else if (market && (market.marketName.toLowerCase().includes('maçın geri kalanını kim kazanır') ||
        market.marketName.toLowerCase().includes('maçın kalanını kim kazanır') ||
        market.marketName.toLowerCase().includes('sıradaki golü kim atar') ||
        withoutSpecialOddValues.includes(market.marketType + '-' + market.marketSubType))) {
        return outcomeName;
    }
    else if (market && (market.marketName === 'Maç Sonucu')) {
        return "".concat(outcomeName);
    }
    else if (market && (market.marketType + '-' + market.marketSubType === '2-718')) {
        return "".concat(outcomeName.split(' ')[0], "/").concat(outcomeName.split(' ')[1]);
    }
    else if (market && (market.marketName.toLowerCase().includes('i̇lk yarı kalanını kim kazanır'))) {
        return "".concat(outcomeName);
    }
    else {
        return "".concat(outcomeName, " ").concat((specialOddsValue === '.5' ? '0.5' : market.specialOddsValue) || '');
    }
}
export function setFilterCompetitions(competitionId) {
    if (competitionId === 'clear') {
        sportsbookFilter.filterCompetition = [];
    }
    else {
        for (var _i = 0, _a = competitionId.split(','); _i < _a.length; _i++) {
            var cid = _a[_i];
            var index = sportsbookFilter.filterCompetition.indexOf(cid);
            if (index === -1) {
                sportsbookFilter.filterCompetition.push(cid);
            }
            else {
                sportsbookFilter.filterCompetition.splice(index, 1);
            }
        }
    }
    window.scrollTo(0, 0);
}
export function setFilterMBS(mbs) {
    if (mbs === 'clear') {
        sportsbookFilter.filterMBS = [];
    }
    else {
        var index = sportsbookFilter.filterMBS.indexOf(mbs);
        if (index === -1) {
            sportsbookFilter.filterMBS.push(mbs);
        }
        else {
            sportsbookFilter.filterMBS.splice(index, 1);
        }
    }
    window.scrollTo(0, 0);
}
export function setFilterDays(day) {
    if (day === 'clear') {
        sportsbookFilter.filterDays = [];
    }
    else {
        var index = sportsbookFilter.filterDays.indexOf(day);
        if (index === -1) {
            sportsbookFilter.filterDays.push(day);
        }
        else {
            sportsbookFilter.filterDays.splice(index, 1);
        }
    }
    window.scrollTo(0, 0);
}
export function generateEventSlider() {
    return __awaiter(this, void 0, void 0, function () {
        var slider, apiService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    slider = env.core_api + '/misc/api/slider/';
                    apiService = new ApiService();
                    return [4 /*yield*/, (function () {
                            return __awaiter(this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, apiService
                                                .start('get', slider, null, false)
                                                .then(function (response) {
                                                if (response.status == true) {
                                                    eventSliderList.splice(0, eventSliderList.length);
                                                    response.data.forEach(function (item) {
                                                        if (Number(item.slide_type) === 1) {
                                                            // console.log(item.content.split(",")[0])
                                                            var selectedEvent = eventMapping[String(item.content.split(",")[0])];
                                                            if (selectedEvent) {
                                                                // console.log(selectedEvent)
                                                                var selectedMarket = null;
                                                                var marketString = item.content.split(",")[1];
                                                                try {
                                                                    selectedMarket = marketIdMapping[selectedEvent.eventId][marketString];
                                                                    if (!selectedMarket || selectedMarket.marketStatus !== 1) {
                                                                        // @ts-ignore
                                                                        for (var _i = 0, _a = Object.entries(selectedEvent.highlightedMarkets); _i < _a.length; _i++) {
                                                                            var _b = _a[_i], key = _b[0], val = _b[1];
                                                                            var ma = selectedEvent.highlightedMarkets[key];
                                                                            if (ma.marketStatus === 1) {
                                                                                selectedMarket = ma;
                                                                                break;
                                                                            }
                                                                        }
                                                                    }
                                                                }
                                                                catch (e) {
                                                                    console.log(e);
                                                                }
                                                                // if (marketString.indexOf('-') !== -1) {
                                                                //   selectedMarket = marketMapping[selectedEvent.eventId].find(
                                                                //     (el: EventMarket) => (Number(el.marketType) === Number(marketString.split('-')[0]) && Number(el.marketSubType) === Number(marketString.split('-')[1]))
                                                                //   )
                                                                // } else {
                                                                //   selectedMarket = marketIdMapping[selectedEvent.eventId][marketString];
                                                                //   if(selectedMarket.marketStatus !== 1){
                                                                //     for(let [key,val] of Object.entries(selectedEvent.highlightedMarkets)){
                                                                //       const ma = selectedEvent.highlightedMarkets[key]
                                                                //       if(ma.marketStatus === 1){
                                                                //         selectedMarket = ma;
                                                                //         break;
                                                                //       }
                                                                //     }
                                                                //   }
                                                                // }
                                                                // console.log(selectedMarket)
                                                                if (selectedMarket) {
                                                                    var slideObject = {
                                                                        id: item.id,
                                                                        event: selectedEvent,
                                                                        eventId: selectedEvent.eventId,
                                                                        marketId: selectedMarket.marketId,
                                                                        slideType: 1,
                                                                        content: null,
                                                                        imageHome: null,
                                                                        imageAway: null,
                                                                        isStarted: null,
                                                                        customName: null,
                                                                        market: selectedMarket
                                                                    };
                                                                    eventSliderList.push(slideObject);
                                                                }
                                                            }
                                                        }
                                                        else if (Number(item.slide_type) === 0) {
                                                            eventSliderList.push({
                                                                slideType: 0,
                                                                content: item.content,
                                                            });
                                                        }
                                                    });
                                                    eventSliderSubject.next(true);
                                                }
                                            })];
                                        case 1:
                                            _a.sent();
                                            return [2 /*return*/];
                                    }
                                });
                            });
                        })()];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
function getHighLightedEvents() {
    return __awaiter(this, void 0, void 0, function () {
        var apiServices;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiServices = new ApiService();
                    return [4 /*yield*/, apiServices.start('get', env.sportsbook_api + '/get-highlighted-events/', null, false)
                            .then(function (response) {
                            var temp = [];
                            for (var _i = 0, _a = Object.entries(response.data); _i < _a.length; _i++) {
                                var _b = _a[_i], k = _b[0], v = _b[1];
                                if (eventMapping[k.toString()]) {
                                    eventMapping[k.toString()].customStyle = v;
                                    temp.push(eventMapping[k.toString()]);
                                }
                            }
                            highlightedEvents.push.apply(highlightedEvents, _.orderBy(temp, ['eventDate']).slice(0, 8));
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function generateCompetitionGroupList(shownSportsBook, orderBy) {
    if (orderBy === void 0) { orderBy = null; }
    var competitionList = [];
    var groupedByCompetition = _.groupBy(shownSportsBook, function (item) {
        // item.competitionName = item.competitionName.replace(new RegExp(',', 'g'), '')
        if (customCompetitionGroupNames[item.competitionId.toString()]) {
            item.competitionGroupName = customCompetitionGroupNames[item.competitionId.toString()]['name'];
            item.competitionPriority = customCompetitionGroupNames[item.competitionId.toString()]['priority'];
        }
        return [item.sportId, item.sportSlug, item.countryName.replace(new RegExp(',', 'g'), '').replace(new RegExp(' ', 'g'), '_'), item.competitionGroupName.replace(new RegExp(',', 'g'), ''), item.eventType];
    });
    var _loop_3 = function (key, value) {
        var group = groupedByCompetition[key];
        // console.log("group>>>", group)
        var reGroupedCompetitions = _.groupBy(group, 'competitionName');
        // console.log("reGroupedCompetitions",reGroupedCompetitions)
        var customPriority = customCompetitionPriorty.find(function (ex) { return ex.competitionGroupName === key.split(',')[3]; });
        for (var _c = 0, _d = Object.entries(customCompetitionGroupNames); _c < _d.length; _c++) {
            var _e = _d[_c], _key = _e[0], _value = _e[1];
            // @ts-ignore
            if (key.split(',')[3] === _value['name']) {
                // @ts-ignore
                customPriority = { "competitionPriority": _value['priority'] };
            }
        }
        competitionList.push({
            sportId: key.split(',')[0],
            sportSlug: key.split(',')[1],
            countryName: key.split(',')[2],
            competitionGroupName: key.split(',')[3].replace('İ', 'III').replace('Ç', 'CCC').replace('Ş', 'SSS'),
            competitions: reGroupedCompetitions,
            count: group.length,
            competitionPriority: customPriority ? customPriority.competitionPriority : '99999999',
            competitionVolume: customPriority ? customPriority.competitionVolume : group.reduce(function (a, b) {
                return a + b['marketOutcomeCount'];
            }, 0),
            competitionCount: {
                pre: group.filter(function (el) { return el.isLiveEvent === "0" || el.isLiveEvent === "1"; }).length,
                live: group.filter(function (el) { return el.isLiveEvent === "1"; }).length,
            }
        });
    };
    // console.log(groupedByCompetition)
    // @ts-ignore
    for (var _i = 0, _a = Object.entries(groupedByCompetition); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        _loop_3(key, value);
    }
    if (orderBy) {
        return _.orderBy(competitionList, orderBy);
    }
    return _.orderBy(competitionList, ['competitionPriority']);
    // return _.orderBy(competitionList, ['competitionVolume'], ['desc']).slice(0,7).concat(_.orderBy(_.orderBy(competitionList, ['competitionVolume'], ['desc']).slice(7,9999999), ['competitionGroupName'], ['asc']))
}
export function getLiveStreamUrl(event) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(event.streamUrl && event.streamUrl.url)) return [3 /*break*/, 2];
                    return [4 /*yield*/, event.streamUrl];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService
                            .start('get', env.stream_url + '/event/' + Storage.get('token') + '/' + event.eventId + '/', null, false)
                            .then(function (response) {
                            if (response.status == true) {
                                if (response.data && response.data.streamUrl && response.data.streamUrl.hls) {
                                    event.streamUrl = response.data.streamUrl.hls;
                                    return response.data.streamUrl.hls;
                                }
                                else {
                                    return null;
                                }
                            }
                        }).catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 3: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function removeEndedEvents() {
    // console.log("removeEndedEvents>>>", env.sportsbook_api+'/events/?fields=eventId')
    var apiService2 = new ApiService();
    apiService2.start('get', env.sportsbook_api + '/events/?fields=eventId', null, false)
        .then(function (response) {
        var updated = [];
        if (response.status && response.data.length > 0) {
            var eventIds_1 = response.data.map(function (el) {
                return Number(el.eventId);
            });
            var mustRemove = sportsbookList.filter(function (event) { return !eventIds_1.includes(Number(event.eventId)); });
            // console.log("biten maclar", mustRemove)
            for (var _i = 0, mustRemove_1 = mustRemove; _i < mustRemove_1.length; _i++) {
                var mr = mustRemove_1[_i];
                mr.isRemoved = true;
                if (mr.isAdded) {
                    addToBetSlip(mr, null, null, 'removeEvent');
                }
                updated.push(mr.eventId);
            }
        }
        // console.log(updated)
        if (updated.length > 0) {
            eventUpdateSubject.next(updated);
        }
    });
}
export function getAlarmSubscriptions() {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiService = new ApiService();
                    apiService.timeout = 3000;
                    return [4 /*yield*/, apiService.start('post', env.scores + '/api/get-subscriptions/', { token: Storage.get('token') }, true)
                            .then(function (res) {
                            return res;
                        }).catch(function (e) {
                            console.log(e);
                            return null;
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function fillHighLightedMarkets(event, selectedHlCode) {
    if (selectedHlCode === void 0) { selectedHlCode = null; }
    if (selectedHlCode === null) {
        event.highlightedMarkets = {};
    }
    if (selectedHlCode === '4-140' && event.highlightedMarkets['4-140']
        && (Number(event.highlightedMarkets['4-140'].marketStatus) === 0 ||
            Number(event.highlightedMarkets['4-140'].marketStatus) > 1)) {
        delete event.highlightedMarkets['4-140'];
    }
    // console.log(event.sportSlug)
    var hLCodes = highLightedMarketCategories[event.sportSlug];
    if (hLCodes) {
        var isLiveOrPre_1 = Number(event.bettingPhase) === 0 ? 'pre' : 'live';
        hLCodes.map(function (hlMap) {
            var hlCode = hlMap[isLiveOrPre_1];
            if (hlCode === selectedHlCode || selectedHlCode === null) {
                var hlCodeArray = hlCode.split('-');
                var marketType_1 = Number(hlCodeArray[0]);
                var hlMarketSubType_1 = Number(hlCodeArray[1]);
                var specialOddsValue_1;
                if (hlCodeArray.length > 2) {
                    specialOddsValue_1 = hlCodeArray[2];
                }
                var availableMarkets = marketMapping[event.eventId].filter(function (m) {
                    if ((specialOddsValue_1 === undefined || Number(m.specialOddsValue) === Number(specialOddsValue_1)) &&
                        (Number(m.marketType) === marketType_1 && Number(m.marketSubType) === hlMarketSubType_1) &&
                        (Number(m.channelWeb) === 1) &&
                        ((Number(m.marketStatus) < 0 || (Number(m.marketStatus) === 1)) && new Date(m.sellEndDate) > ServerDate() && new Date(m.sellBeginDate) < ServerDate())) {
                        return m;
                    }
                });
                if (availableMarkets.length > 0) {
                    if (availableMarkets.length === 1) {
                        event.highlightedMarkets[hlCode] = availableMarkets[0];
                    }
                    else {
                        for (var _i = 0, availableMarkets_1 = availableMarkets; _i < availableMarkets_1.length; _i++) {
                            var market = availableMarkets_1[_i];
                            var diff = 0;
                            var diff2 = 0;
                            var bestChoice = false;
                            if (event.highlightedMarkets[hlCode]) {
                                diff = Math.abs(Number(event.highlightedMarkets[hlCode].marketOutcome[0].fixedOddsWeb) - Number(event.highlightedMarkets[hlCode].marketOutcome[1].fixedOddsWeb));
                                diff2 = Math.abs(Number(market.marketOutcome[0].fixedOddsWeb) - Number(market.marketOutcome[1].fixedOddsWeb));
                                if (diff2 < diff && diff2 > 0) {
                                    bestChoice = true;
                                }
                            }
                            if (event.highlightedMarkets[hlCode] === undefined || bestChoice) {
                                event.highlightedMarkets[hlCode] = market;
                            }
                        }
                    }
                }
            }
        });
    }
}
export function checkAndAddHighLightedMarkets(event, market) {
    var hLCodes = highLightedMarketCategories[event.sportSlug];
    var isLiveOrPre = Number(event.bettingPhase) === 0 ? 'pre' : 'live';
    if (hLCodes) {
        var isSuitable = hLCodes.filter(function (el) { return el[isLiveOrPre] === market.marketType + '-' + market.marketSubType || el[isLiveOrPre] === market.marketType + '-' + market.marketSubType + '-' + market.specialOddsValue; });
        if (isSuitable.length > 0) {
            isSuitable = isSuitable[0];
            var hlCode = isSuitable[isLiveOrPre];
            var hlCodeArray = hlCode.split('-');
            var marketType_2 = Number(hlCodeArray[0]);
            var hlMarketSubType_2 = Number(hlCodeArray[1]);
            var specialOddsValue_2;
            if (hlCodeArray.length > 2) {
                specialOddsValue_2 = hlCodeArray[2];
            }
            var availableMarkets = marketMapping[event.eventId].filter(function (m) {
                if ((specialOddsValue_2 === undefined || Number(m.specialOddsValue) === Number(specialOddsValue_2)) &&
                    (Number(m.marketType) === marketType_2 && Number(m.marketSubType) === hlMarketSubType_2) &&
                    (Number(m.marketStatus) < 2) && (Number(m.marketId) !== Number(market.marketId)) &&
                    (Number(m.channelWeb) === 1)) {
                    return m;
                }
            });
            if (availableMarkets.length === 0) {
                if (!event.highlightedMarkets) {
                    event.highlightedMarkets = {};
                }
                event.highlightedMarkets[hlCode] = market;
            }
            else {
                for (var _i = 0, availableMarkets_2 = availableMarkets; _i < availableMarkets_2.length; _i++) {
                    var market_1 = availableMarkets_2[_i];
                    var diff = 0;
                    var diff2 = 0;
                    var bestChoice = false;
                    if (event.highlightedMarkets && event.highlightedMarkets[hlCode]) {
                        diff = Math.abs(Number(event.highlightedMarkets[hlCode].marketOutcome[0].fixedOddsWeb) - Number(event.highlightedMarkets[hlCode].marketOutcome[1].fixedOddsWeb));
                        diff2 = Math.abs(Number(market_1.marketOutcome[0].fixedOddsWeb) - Number(market_1.marketOutcome[1].fixedOddsWeb));
                        if (diff2 < diff && diff2 > 0) {
                            bestChoice = true;
                        }
                        else {
                            bestChoice = false;
                        }
                    }
                    if (event.highlightedMarkets && event.highlightedMarkets[hlCode] === undefined || bestChoice) {
                        event.highlightedMarkets[hlCode] = market_1;
                    }
                }
            }
        }
    }
}
export function updateEventComments(data, action) {
    if (action === 'add' && data && data.obj_id) {
        if (data.content_type === 1) {
            var event_1 = eventMapping[String(data.obj_id)];
            if (event_1 && event_1.comments && data.member_id !== member.id) {
                getMemberInfo({ member_id: data.member_id }).then(function (res) {
                    if (res && res.length > 0) {
                        data.member = {
                            member_id: res[0].member_id,
                            photo: res[0].photo,
                            nick_name: res[0].nick_name,
                        };
                    }
                    if (event_1.comments) {
                        event_1.comments.unshift(data);
                        commentUpdateSubject.next(Number(event_1.eventId));
                    }
                });
            }
        }
        if (data.content_type === 4 && (!member || data.member_id !== member.id)) {
            console.log("---->>", data);
            getMemberInfo({ member_id: data.member_id }).then(function (res) {
                if (res && res.length > 0) {
                    data.member = {
                        member_id: res[0].member_id,
                        photo: res[0].photo,
                        nick_name: res[0].nick_name,
                    };
                }
            });
        }
    }
    if (action === 'remove' && data) {
        // console.log(data)
        var event_2 = eventMapping[String(data.obj_id)];
        // console.log(event)
        if (event_2 && event_2.comments) {
            // console.log(Number(data.post_id))
            event_2.comments = event_2.comments.filter(function (el) { return Number(el.post_id) !== Number(data.post_id); });
            commentUpdateSubject.next(Number(event_2.eventId));
        }
    }
    if (action === 'update' && data) {
        var event_3 = eventMapping[String(data.obj_id)];
        if (event_3 && event_3.comments) {
            commentUpdateSubject.next(Number(event_3.eventId));
        }
    }
}
export function fillEventStatistics(ids) {
    return __awaiter(this, void 0, void 0, function () {
        var apiService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    apiService = new ApiService();
                    return [4 /*yield*/, apiService.start('get', env.sportsbook_api + '/get-event-statistics/?events=' + ids.join(','), null, false)
                            .then(function (response) {
                            if (response.status === true) {
                                if (response.data.length > 0) {
                                    response.data.forEach(function (stat) {
                                        statisticsMap[stat.eventId] = stat;
                                    });
                                }
                            }
                        })
                            .catch(function (e) {
                            console.log(e);
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getCustomCompetitionPriorty() {
    return __awaiter(this, void 0, void 0, function () {
        var cachedData, apiService;
        return __generator(this, function (_a) {
            cachedData = Storage.get('customCompetitionPriorty');
            if (cachedData) {
                Object.assign(customCompetitionPriorty, cachedData);
            }
            apiService = new ApiService();
            apiService.start('get', env.cdn_url + '/json/competition_priority.json', null, false)
                .then(function (res) {
                Object.assign(customCompetitionPriorty, res);
            });
            return [2 /*return*/];
        });
    });
}
export function getCustomCompetitionNames() {
    return __awaiter(this, void 0, void 0, function () {
        var cachedData, apiService;
        return __generator(this, function (_a) {
            cachedData = Storage.get('customCompetitionGroupNames');
            if (cachedData) {
                Object.assign(customCompetitionGroupNames, cachedData);
            }
            apiService = new ApiService();
            apiService.start('get', env.cdn_url + '/json/competition_map.json', null, false)
                .then(function (res) {
                Object.assign(customCompetitionGroupNames, res);
            });
            return [2 /*return*/];
        });
    });
}
export function reOrganizeEventRatios() {
    sportsbookList.forEach(function (event) {
        event.hasCustomratio = false;
        marketMapping[event.eventId.toString()].forEach(function (market) {
            market.marketOutcome.forEach(function (outcome) {
                if (Number(outcome.fixedOddsWeb) < Number(outcome.fixedOdds) || (member && member.additional_ratio && Number(member.additional_ratio) > 0)) {
                    if (member && member.additional_ratio && Number(member.additional_ratio) > 0) {
                        outcome.fixedOdds = Number(outcome.fixedOddsWeb) + Number(member.additional_ratio);
                    }
                    outcome.customOdds = Number(outcome.fixedOdds);
                    event.hasCustomratio = true;
                }
                else {
                    outcome.customOdds = null;
                }
            });
        });
    });
}
export function prepairSportsbookList(setLoading, response) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, response.data.forEach(function (event) {
                        // fixme : bu kısım event veri boyutu için yapılmış mapping.
                        // for( let [k,v] of Object.entries(EVENT_MAP)){
                        //   delete Object.assign(event, {[k]: event[v] })[v];
                        //   delete event[k];
                        // }
                        // console.log(event)
                        eventMapping[event.eventId.toString()] = event;
                        if (event.markets) {
                            event.hasCustomratio = false;
                            marketMapping[event.eventId.toString()] = event.markets.filter(function (m) { return Number(m.channelWeb) === 1; });
                            event.markets.forEach(function (market) {
                                if (!marketIdMapping[event.eventId.toString()]) {
                                    marketIdMapping[event.eventId.toString()] = {};
                                }
                                market.marketOutcome.forEach(function (outcome) {
                                    if (Number(outcome.fixedOddsWeb) < Number(outcome.fixedOdds) || (member && member.additional_ratio && Number(member.additional_ratio) > 0)) {
                                        if (member && member.additional_ratio && Number(member.additional_ratio) > 0) {
                                            outcome.fixedOdds = Number(outcome.fixedOddsWeb) + Number(member.additional_ratio);
                                        }
                                        outcome.customOdds = Number(outcome.fixedOdds);
                                        event.hasCustomratio = true;
                                    }
                                    else {
                                        outcome.customOdds = null;
                                    }
                                });
                                marketIdMapping[event.eventId.toString()][market.marketId] = market;
                            });
                            event.markets = [];
                            fillHighLightedMarkets(event);
                        }
                        // @ts-ignore
                        window.eventMapping = eventMapping;
                        // @ts-ignore
                        window.marketMapping = marketMapping;
                        sportsbookList.push(eventMapping[event.eventId.toString()]);
                    })
                    // Start WSS Updater
                ];
                case 1:
                    _a.sent();
                    // Start WSS Updater
                    SportbookWS.connect(SportbookWS.SPORTBOOK, null, true);
                    return [4 /*yield*/, getSportSummery()];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, generateEventSlider().then(function () {
                            getHighLightedEvents().then(function () {
                                if (BetSlipList.length === 0) {
                                    restoreBetSlip();
                                    initializeBetSlip();
                                }
                                setLoading(false);
                            });
                        }).catch(function (e) {
                            console.log("Slider cant loaded");
                            console.log(e);
                        })];
                case 3:
                    _a.sent();
                    return [4 /*yield*/, getAlarmSubscriptions().then(function (res) {
                            if (res && res.events) {
                                for (var _i = 0, _a = res.events; _i < _a.length; _i++) {
                                    var eventId = _a[_i];
                                    if (eventMapping[eventId]) {
                                        eventMapping[eventId].alarmStatus = true;
                                    }
                                }
                            }
                        })];
                case 4:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
var SportsBookService = function (props) {
    // fixme : alternatif bir kurgu şart , yükleme sıralaması gerekli
    var _a = useState(true), isLoading = _a[0], setLoading = _a[1];
    var popularCompetitionUrl = env.cdn_url + "/json/popularLeagues.json";
    var apiService = new ApiService();
    useEffect(function () {
        if (sportsbookList.length === 0) {
            getCustomCompetitionPriorty();
            getCustomCompetitionNames();
            (function () {
                return __awaiter(this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, apiService
                                    .start('get', popularCompetitionUrl, null, false)
                                    .then(function (response) {
                                    popularCompetition.push.apply(popularCompetition, response);
                                })];
                            case 1:
                                _a.sent();
                                return [4 /*yield*/, apiService
                                        .start('get', sportsbookApi, null, false, 30000)
                                        .then(function (response) {
                                        if (response.status && response.data) {
                                            // console.log("1 - ",new Date().getTime())
                                            // getRatioCampaignInfo().then((res:any)=>{
                                            //   // console.log("2 - ",new Date().getTime())
                                            //   prepairSportsbookList(setLoading, response, res);
                                            //   // console.log("3 - ",new Date().getTime())
                                            // }).catch(()=>{
                                            //   // console.log("4 - ",new Date().getTime())
                                            //   prepairSportsbookList(setLoading, response);
                                            // });
                                            prepairSportsbookList(setLoading, response);
                                        }
                                    })
                                        .catch(function (e) {
                                        // console.log("Sportsbook Api servis")
                                        // console.log(e)
                                        setLoading(false);
                                        setTimeout(function () {
                                            modalServiceSubject.next({
                                                title: 'Bağlantı hatası',
                                                content: 'Lütfen internet bağlantınız kontrol edin. İnternet Bağlantınız yok ya da çok zayıf.',
                                                confirm: {
                                                    sure: {
                                                        label: 'Tekrar Dene',
                                                        action: function () {
                                                            window.location.reload();
                                                        }
                                                    }
                                                }
                                            });
                                        }, 500);
                                    })];
                            case 2:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            })();
        }
    }, []);
    if (isLoading) {
        return _jsxs("div", __assign({ className: "App splash" }, { children: [_jsx("br", {}, void 0), "Y\u00FCkleniyor..."] }), void 0);
    }
    return (_jsx(_Fragment, { children: props.children }, void 0));
};
export default SportsBookService;
export function compireEventKeys(exEvent, newEvent) {
    var isUpdated = false;
    for (var _i = 0, _a = Object.entries(newEvent); _i < _a.length; _i++) {
        var _b = _a[_i], key = _b[0], value = _b[1];
        // console.log(key)
        if (exEvent && exEvent[key] !== value) {
            if (key === 'bettingPhase' && Number(exEvent[key]) === 0 && Number(value) === 1) {
                fillHighLightedMarkets(exEvent);
            }
            if (key !== 'markets') {
                exEvent[key] = value;
                isUpdated = true;
            }
        }
    }
    return isUpdated;
}
export function compireMarketKeys(event, exMarket, newMarket) {
    var isUpdated = false;
    if (!exMarket && newMarket) {
        marketIdMapping[newMarket.event][newMarket.marketId] = newMarket;
        marketMapping[newMarket.event].push(newMarket);
        checkAndAddHighLightedMarkets(event, marketIdMapping[newMarket.event][newMarket.marketId]);
        isUpdated = true;
    }
    else if (exMarket && newMarket) {
        for (var _i = 0, _a = Object.entries(newMarket); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            if (key !== 'marketOutcome') {
                if (exMarket.hasOwnProperty(key) && exMarket[key] !== value) {
                    exMarket[key] = value;
                    if (eventMapping[String(newMarket.event)].highlightedMarkets && eventMapping[String(newMarket.event)].highlightedMarkets[exMarket.marketType + '-' + exMarket.marketSubType]) {
                        fillHighLightedMarkets(eventMapping[String(newMarket.event)], exMarket.marketType + '-' + exMarket.marketSubType);
                    }
                    isUpdated = true;
                }
            }
            else {
                exMarket[key].forEach(function (outcome) {
                    var incomingMarketOutcome = newMarket['marketOutcome'].find(function (el) { return el.outcomeNo === outcome.outcomeNo; });
                    if (incomingMarketOutcome) {
                        if (outcome.fixedOddsWeb !== incomingMarketOutcome.fixedOddsWeb || Number(outcome.oddsVersion) <= Number(incomingMarketOutcome.oddsVersion)) {
                            if (Number(outcome.fixedOddsWeb) < Number(incomingMarketOutcome.fixedOddsWeb)) {
                                outcome.isUpdated = 'up';
                            }
                            else if (Number(outcome.fixedOddsWeb) > Number(incomingMarketOutcome.fixedOddsWeb)) {
                                outcome.isUpdated = 'down';
                            }
                            outcome.fixedOddsWeb = incomingMarketOutcome.fixedOddsWeb;
                            outcome.fixedOdds = incomingMarketOutcome.fixedOdds;
                            if (Number(outcome.fixedOddsWeb) < Number(outcome.fixedOdds) || (member && member.additional_ratio && Number(member.additional_ratio) > 0)) {
                                if (member && member.additional_ratio && Number(member.additional_ratio) > 0) {
                                    outcome.fixedOdds = Number(outcome.fixedOddsWeb) + Number(member.additional_ratio);
                                }
                                outcome.customOdds = Number(outcome.fixedOdds);
                                event.hasCustomratio = true;
                            }
                            else {
                                outcome.customOdds = null;
                            }
                            setTimeout(function () {
                                outcome.isUpdated = null;
                                eventUpdateSubject.next([String(newMarket.event)]);
                            }, 5000);
                            isUpdated = true;
                        }
                    }
                });
            }
        }
    }
    return isUpdated;
}
export function addToSportsBook(newEvent) {
    var updated = false;
    if (newEvent && (newEvent.isLiveEvent === '1' && eventEndedStatuses.indexOf(newEvent.liveScoreStatus) === -1) && (newEvent.isLiveEvent === '0' && new Date(newEvent.eventDate) >= ServerDate())) {
        eventMapping[newEvent.eventId.toString()] = newEvent;
        marketMapping[newEvent.eventId.toString()] = newEvent.markets.filter(function (m) { return Number(m.marketStatus) < 2; });
        marketMapping[newEvent.eventId.toString()].forEach(function (market) {
            if (!marketIdMapping[newEvent.eventId.toString()]) {
                marketIdMapping[newEvent.eventId.toString()] = {};
            }
            marketIdMapping[newEvent.eventId.toString()][market.marketId] = market;
        });
        newEvent.markets = [];
        fillHighLightedMarkets(eventMapping[newEvent.eventId.toString()]);
        sportsbookList.push(eventMapping[newEvent.eventId.toString()]);
        updated = true;
    }
    return updated;
}
export function getUpdateFromSportsbook(apiUrl) {
    var apiService = new ApiService();
    apiService.start('get', apiUrl, null, false)
        .then(function (response) {
        var updated = [];
        // console.log(response)
        if (response.status) {
            // console.log("START", response.data.length )
            response.data.forEach(function (incomeEvent) {
                var exEvent = eventMapping[incomeEvent.eventId.toString()];
                if (!exEvent) {
                    if (addToSportsBook(incomeEvent)) {
                        // console.log("Event Yoktu eklenecek ",incomeEvent.eventId.toString())
                        updated.push(incomeEvent.eventId.toString());
                    }
                }
                else {
                    if (compireEventKeys(exEvent, incomeEvent)) {
                        updated.push(incomeEvent.eventId.toString());
                    }
                    for (var _i = 0, _a = incomeEvent.markets; _i < _a.length; _i++) {
                        var market = _a[_i];
                        if (!marketIdMapping[exEvent.eventId][market.marketId] || (Number(marketIdMapping[exEvent.eventId][market.marketId].marketVersion) <= Number(market.marketVersion))) {
                            if (compireMarketKeys(exEvent, marketIdMapping[exEvent.eventId][market.marketId], market)) {
                                updated.push(incomeEvent.eventId.toString());
                            }
                        }
                    }
                    var removedMarkets = [];
                    var _loop_4 = function (market) {
                        var marketIndex = incomeEvent.markets.find(function (el) { return Number(el.marketId) === Number(market.marketId); });
                        if (!marketIndex) {
                            var ex = marketMapping[exEvent.eventId].findIndex(function (el) { return Number(el.marketId) === Number(market.marketId); });
                            marketMapping[exEvent.eventId].splice(ex, 1);
                            delete marketIdMapping[exEvent.eventId][market.marketId];
                            removedMarkets.push(market.marketId);
                            updated.push(exEvent.eventId.toString());
                        }
                    };
                    for (var _b = 0, _c = marketMapping[exEvent.eventId]; _b < _c.length; _b++) {
                        var market = _c[_b];
                        _loop_4(market);
                    }
                }
            });
        }
        if (updated.length > 0) {
            eventUpdateSubject.next(updated);
        }
    });
}
