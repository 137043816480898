var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// import AndroidIosApp from '../../../assets/img/android-ios-app.webp';
var Iphone = function () {
    return (_jsx("div", { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-9" }, { children: [_jsx("h5", { children: " iPhone Uygulamas\u0131 Nas\u0131l \u0130ndirilir?" }, void 0), _jsx("hr", {}, void 0), _jsxs("p", { children: ["1. A\u015Fa\u011F\u0131daki butona t\u0131klayarak uygulamay\u0131 iPhone marka cep telefonunuza indirebilirsiniz.", _jsx("br", {}, void 0), _jsx("br", {}, void 0), _jsx("a", __assign({ target: "_blank", href: "#" }, { children: "Uygulamay\u0131 \u0130ndir" }), void 0)] }, void 0)] }), void 0), _jsx("div", { className: "col-3" }, void 0), _jsx("hr", {}, void 0)] }), void 0) }, void 0));
};
export default Iphone;
