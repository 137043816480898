var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from 'react';
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import { Storage } from "../../../../lib/localstorege.service";
import { modalServiceSubject } from "../../../../components/modal/modal";
import { GameOperationsApi } from '../../subpages/gameOperations/game.operations.api';
function SosyoleyShare(props) {
    var coupon = props.coupon;
    var _a = React.useState(false), modalIsOpenShare = _a[0], setIsOpenShare = _a[1];
    var token = Storage.get('token');
    function openModalShare() {
        setIsOpenShare(true);
    }
    function closeModal() {
        setIsOpenShare(false);
    }
    var _b = useForm(), register = _b.register, handleSubmit = _b.handleSubmit;
    var onSubmit = function (data) {
        data.coupon_id = Number(coupon.id);
        data.action = "share";
        data.token = token;
        shareSosyoley(data);
    };
    var shareSosyoley = function (data) {
        if (navigator.onLine) {
            GameOperationsApi.shareSosyoley(data).then(function (result) {
                if (result.status) {
                    setIsOpenShare(false);
                    modalServiceSubject.next({
                        title: "Uyarı!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                    props.closeModal();
                }
                else {
                    modalServiceSubject.next({
                        title: "Hata!",
                        content: result.message,
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0
                    });
                }
            }).catch(function (error) {
                modalServiceSubject.next({
                    title: "Uyarı!",
                    content: error,
                    confirm: {
                        cancel: { label: 'Tamam' }
                    },
                    timeout: 0
                });
            });
        }
        else {
            modalServiceSubject.next({
                title: "Uyarı!",
                content: "İnternet bağlantınızı kontrol ediniz.",
                confirm: {
                    cancel: { label: 'Tamam' }
                },
                timeout: 0
            });
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "float-right social-share" }, { children: _jsx("button", __assign({ onClick: function () { return openModalShare(); }, className: "btn btn-warning btn-small" }, { children: "Sosyoley'de Payla\u015F" }), void 0) }), void 0), _jsx(Modal, __assign({ isOpen: modalIsOpenShare, onRequestClose: closeModal, style: {
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        width: '600px',
                        marginRight: '-50%',
                        maxWidth: ' calc( 100% - 40px )',
                        padding: '15px',
                        transform: 'translate(-50%, -50%)',
                        background: 'var(--color-bg-white)',
                    }
                }, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "card mt-0" }, { children: [_jsx("div", __assign({ className: "title" }, { children: _jsx("h3", { children: "Emin misiniz?" }, void 0) }), void 0), _jsx("div", __assign({ className: "body" }, { children: _jsxs("form", __assign({ onSubmit: handleSubmit(onSubmit) }, { children: [_jsx("div", { children: _jsx("textarea", __assign({ rows: 5, className: "form-control fs-medium", placeholder: "Kuponu Yorumla" }, register('comment')), void 0) }, void 0), _jsxs("div", __assign({ className: "text-right" }, { children: [_jsx("button", __assign({ onClick: function () { return closeModal(); }, className: "btn btn-defaul mt-3" }, { children: "Vazge\u00E7" }), void 0), _jsx("input", { className: "btn btn-primary mt-3", type: "submit", value: "Payla\u015F" }, void 0)] }), void 0)] }), void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
}
export default SosyoleyShare;
