var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect } from 'react';
import Menu from "./widgets/menu";
import Contact from "./widgets/contact";
// import iconCoupons from "../../assets/img/coupons-icon.png"
import iconHome from "../../assets/img/home-icon.png";
import iconBet from "../../assets/img/bet-icon.png";
import { Link, useLocation } from "react-router-dom";
import { betSlipActionSubject, BetSlipList, BetSlipOption, betSlipVisibleSubject } from "../../store/betslip.store";
import { useForceUpdate } from "../../lib/misc.functions";
import { betSlipVisiblity } from "../betslip/betslip";
import { location, member } from "../../store/login.store";
import { Location } from '../../models/user.model';
import { SportWSProvider } from "../../services/websocket.services";
export default function Footer() {
    return (_jsx("div", __assign({ className: "footer mt-5 mb-5" }, { children: _jsxs("footer", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-lg-6 col-md-8 hidden-sm" }, { children: _jsx(Menu, {}, void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-6 col-md-4 col-sm-12  hidden-sm" }, { children: _jsx(Contact, {}, void 0) }), void 0)] }), void 0) }), void 0));
}
var connTimer;
var isFirstLoad = true;
export function ConnectionController() {
    var forceUpdate = useForceUpdate();
    useEffect(function () {
        connTimer = setInterval(function () {
            forceUpdate();
        }, 500);
        return function () {
            clearInterval(connTimer);
        };
    }, []);
    if ((SportWSProvider && SportWSProvider.readyState === 1) || isFirstLoad === true) {
        isFirstLoad = false;
        return (_jsx(_Fragment, {}, void 0));
    }
    else {
        isFirstLoad = false;
        return (_jsx(_Fragment, { children: _jsx("div", __assign({ className: "connection-controller" }, { children: "L\u00FCtfen internet ba\u011Flant\u0131n\u0131z\u0131 kontrol ediniz." }), void 0) }, void 0));
    }
}
export function MobileFooter() {
    var forceUpdate = useForceUpdate();
    var pathname = useLocation().pathname;
    useEffect(function () {
        var betSlipActionSubject_subscription = betSlipActionSubject.subscribe(function (events) {
            if (['toggle', 'clear', 'restore'].includes(events.action)) {
                forceUpdate();
            }
        });
        return function () {
            betSlipActionSubject_subscription.unsubscribe();
        };
    }, []);
    function onLocation(selectlocation) {
        var object = new Location();
        object.path = selectlocation;
        Object.assign(location, object);
    }
    var resetUnreadIndicator = function () {
        var unreadIndicator = document.getElementById('unreadIndicator');
        var unreadIndicator2 = document.getElementById('unreadIndicator2');
        // @ts-ignore
        unreadIndicator.style.display = 'none';
        // @ts-ignore
        unreadIndicator2.style.display = 'none';
        // @ts-ignore
        unreadIndicator.innerHTML = '0';
        // @ts-ignore
        unreadIndicator2.innerHTML = '0';
    };
    var populateUnreadIndicator = function (count) {
        if (!count)
            return resetUnreadIndicator();
        var unreadIndicator = document.getElementById('unreadIndicator');
        var unreadIndicator2 = document.getElementById('unreadIndicator2');
        if (unreadIndicator && unreadIndicator2) {
            // @ts-ignore
            unreadIndicator.style.display = 'block';
            // @ts-ignore
            unreadIndicator2.style.display = 'block';
            // @ts-ignore
            unreadIndicator.innerHTML = count;
            // @ts-ignore
            unreadIndicator2.innerHTML = count;
        }
    };
    // // @ts-ignore
    // zE('messenger:on', 'unreadMessages', (count) => {
    //   populateUnreadIndicator(count)
    // })
    //
    return (_jsxs(_Fragment, { children: [_jsx("div", __assign({ className: "mobile-footer" }, { children: _jsxs("ul", { children: [_jsx("li", { children: _jsxs(Link, __assign({ to: "/", onClick: function () {
                                    betSlipVisibleSubject.next(false);
                                } }, { children: [_jsx("span", { children: _jsx("img", { src: iconHome }, void 0) }, void 0), _jsx("span", { children: "Anasayfa" }, void 0)] }), void 0) }, void 0), _jsx("li", __assign({ onClick: function () { return onLocation("/hesabim/para-cek"); } }, { children: _jsxs(Link, __assign({ to: member.status ? "/hesabim/iddaa/devam-edenler" : "/giris-yap", onClick: function () {
                                    betSlipVisibleSubject.next(false);
                                } }, { children: [_jsx("span", { children: "iconCoupons" }, void 0), _jsx("span", { children: "Kuponlar\u0131m" }, void 0)] }), void 0) }), void 0), _jsx("li", { children: _jsxs(Link, __assign({ to: "/iddaa/futbol/tumu", onClick: function () {
                                    betSlipVisibleSubject.next(false);
                                } }, { children: [_jsx("span", { children: _jsx("img", { src: iconBet }, void 0) }, void 0), _jsx("span", { children: "\u0130ddaa" }, void 0)] }), void 0) }, void 0), _jsx("li", { children: _jsx(Link, __assign({ to: "kuponlarim/", onClick: function () {
                                    betSlipVisibleSubject.next(false);
                                } }, { children: _jsx("span", { children: "Canl\u0131" }, void 0) }), void 0) }, void 0), _jsx("li", { children: _jsxs("div", { children: [_jsx("div", { className: "bar-start" }, void 0), _jsx("i", __assign({ className: "badge" }, { children: BetSlipList.length }), void 0), _jsxs("a", __assign({ id: "leftSportMenu-btn", onClick: function () {
                                            betSlipVisibleSubject.next(!betSlipVisiblity);
                                        } }, { children: [_jsx("span", { children: "iconBetSlip" }, void 0), _jsx("span", __assign({ className: "nowrap" }, { children: BetSlipOption.totalRatio.toFixed(2) }), void 0)] }), void 0), _jsx("svg", __assign({ height: "50", viewBox: "0 0 100 50", width: "100", xmlns: "http://www.w3.org/2000/svg" }, { children: _jsx("path", { d: "M100 0v50H0V0c.543 27.153 22.72 49 50 49S99.457 27.153 99.99 0h.01z", fillRule: "evenodd" }, void 0) }), void 0), _jsx("div", { className: "bar-end" }, void 0)] }, void 0) }, void 0)] }, void 0) }), void 0), _jsx(ConnectionController, {}, void 0)] }, void 0));
}
