var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { popularCompetition, sportsbookFilter, sportsbookList, sportsSummery, sportsSummerySubject } from "../../store/sportsbook.store";
import { Link, useNavigate } from "react-router-dom";
import Navbar from "../header/components/navbar/navbar";
import { useAuthContext } from "../../store/login.store";
import iconDown from "../../assets/feather/chevron-down.svg";
import { toggleMobileSideMenu, useForceUpdate } from "../../lib/misc.functions";
import { env } from "../../definitions/global.vars";
import Collapsible from "react-collapsible";
import fire from "../../assets/feather/fire.png";
import { generateCompetitionGroupList, setFilterCompetitions } from "../../services/sportsbook.services";
import _ from "lodash";
export default function SideMenu() {
    var openPage = window.location.href;
    var forceUpdate = useForceUpdate();
    var pathname = window.location.pathname;
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var width = window.innerWidth;
    var setFilter = function (competitionId, sportSlug, eventType) {
        sportsbookFilter.filterCompetition = [];
        setFilterCompetitions(competitionId);
        forceUpdate();
        navigate("/iddaa/" + sportSlug + "/" + eventType);
    };
    useEffect(function () {
        var sportsSummerySubject_subscription = sportsSummerySubject.subscribe(function () {
            forceUpdate();
        });
        return function () {
            sportsSummerySubject_subscription.unsubscribe();
        };
    }, []);
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            return "/iddaa/" + sportSlug + '/uzunvadeli';
            // document.body.style.overflow = 'unset';
        }
        else {
            return "/iddaa/" + sportSlug + '/tumu';
            // document.body.style.overflow = 'unset';
        }
    };
    var isActive = function (sportSlug, eventType) {
        if (window.location.pathname.indexOf(sportSlug) !== -1) {
            if (eventType === '2' && window.location.pathname.indexOf('uzunvadeli') !== -1) {
                return 'active';
            }
            if (eventType === '1' && (window.location.pathname.indexOf('tumu') !== -1 || window.location.pathname.indexOf('canli') !== -1)) {
                return 'active';
            }
        }
        return '';
    };
    var popularCompetitions = popularCompetition;
    // console.log(sportsCompetitions);
    return (_jsx(_Fragment, { children: _jsx("div", __assign({ id: "leftSideMenu", className: "left-side-menu", onClick: function () {
                if (width < 968) {
                    toggleMobileSideMenu();
                }
            } }, { children: _jsxs("div", __assign({ id: "sideMenuToggler", className: "toggler" }, { children: [_jsxs("ul", __assign({ id: "sportsList", className: "sports-list" }, { children: [popularCompetitions.length > 0 ? _jsxs(_Fragment, { children: [_jsx(Collapsible, __assign({ open: true, trigger: _jsxs(_Fragment, { children: [_jsx("div", { children: "Pop\u00FCler Kar\u015F\u0131la\u015Fmalar" }, void 0), _jsxs("span", { children: ["+", popularCompetitions.length] }, void 0)] }, void 0) }, { children: popularCompetitions.map(function (popularMartche, key) {
                                            return (_jsxs("a", __assign({ className: "cursor-pointer", onClick: function () {
                                                    // setFilter(popularMartche.id, popularMartche.sport, eventType);
                                                } }, { children: [popularMartche.name, "\u00A0", popularMartche.name === ('UEFA Süper Kupa') ?
                                                        _jsx("div", __assign({ className: 'flames ' }, { children: _jsx("img", { src: fire, className: 'm-auto', height: 18, width: 18 }, void 0) }), void 0) : null, popularMartche.name.includes('Düello') ? _jsxs(_Fragment, { children: [_jsx("span", __assign({ className: 'new-badge blink_me mx-0 my-auto', style: { color: '#fff' }, id: 'newBadgeBtn' }, { children: "YEN\u0130" }), void 0), "  "] }, void 0) : null] }), "popularmartche2" + key));
                                        }) }), void 0), _jsx("hr", {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), sportsSummery.map(function (item, key) {
                                var originSportsbookList = _.orderBy(sportsbookList, ['eventDate', 'competitionGroupName', 'competitionName']).filter(function (el) {
                                    // console.log(params)
                                    if (item.sportSlug === el.sportSlug) {
                                        if ((item.eventType === '1' && Number(el.eventType) === 1) ||
                                            (Number(el.eventType) === 1 && Number(el.isLiveEvent) === 1 && Number(el.eventType) === 1) ||
                                            (item.eventType === '2' && Number(el.eventType) === 2 && el.marketOutcomeCount > 0)) {
                                            return el;
                                        }
                                    }
                                    else if (item.sportSlug == null) {
                                        return el;
                                    }
                                });
                                var sportsCompetitions = generateCompetitionGroupList(originSportsbookList, [item.sportSlug]);
                                return _jsx(Collapsible, __assign({ open: false, trigger: _jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "d-flex" }, { children: [_jsx("div", { className: "icon game-event-icon  ".concat(item.sportSlug), style: { width: "21px", height: "21px" } }, void 0), item.sportName, " ", item.eventType === '2' ? 'UV' : ''] }), void 0), _jsxs("span", { children: [" ", _jsx("img", { className: "invert-color mr-0", style: { height: 16 }, src: iconDown }, void 0), " "] }, void 0)] }, void 0) }, { children: sportsCompetitions.map(function (itemComp, key) {
                                        // console.log(item)
                                        return (_jsx("div", { children: _jsx(Collapsible, __assign({ trigger: _jsxs(_Fragment, { children: [_jsx("img", { src: "https://cdn2.oley.com/images/country_icons_circle/".concat(itemComp.competitionGroupName === "IIIskoçya" ? 'Scotland' : itemComp.countryName, ".png") }, void 0), _jsx("div", { children: itemComp.competitionGroupName.replace('CCC', 'Ç').replace('SSS', 'Ş').replace('III', 'İ') }, void 0), _jsxs("span", { children: ["+", itemComp.count] }, void 0)] }, void 0) }, { children: Object.entries(itemComp.competitions).map(function (comp, key) {
                                                    return (_jsxs("a", __assign({ className: "cursor-pointer", onClick: function () {
                                                            setFilter(comp[1][0].competitionId, item.sportSlug, item.eventType === '2' ? 'uzunvadeli' : 'tumu');
                                                        } }, { children: [_jsx("div", { children: comp[1][0].competitionName.replace('UEFA Şampiyonlar Ligi Elemeler', 'UEFA Şampiyonlar Ligi Elemeleri').replace('UEFA Avrupa Ligi, Elemeler', 'UEFA Avrupa Ligi Elemeleri') }, void 0), _jsxs("span", { children: ["+", comp[1].length] }, void 0)] }), "iddiamarketmenu2event".concat(key)));
                                                }) }), "eventSporMenu2".concat(key)) }, itemComp.sportSlug + '_' + key));
                                    }) }), key);
                            })] }), void 0), _jsxs("div", __assign({ id: "mobileNavMenu", className: "nav" }, { children: [_jsx(Navbar, {}, void 0), isAuthenticated ? _jsx(_Fragment, { children: _jsxs("nav", __assign({ className: "row mt-2" }, { children: [_jsx("span", __assign({ className: "left-menu-separator" }, { children: "\u00DCyelik Men\u00FC" }), void 0), _jsx(Link, __assign({ style: { borderTop: '1px solid #00000022' }, to: "hesabim/uyelik", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "\u00DCyelik Bilgilerim" }), void 0), _jsx(Link, __assign({ to: "hesabim/guvenlik", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "\u015Eifre De\u011Fi\u015Ftir" }), void 0), _jsx(Link, __assign({ to: "hesabim/hesap-hareketlerim", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Hesap Hareketlerim" }), void 0), _jsx(Link, __assign({ to: "hesabim/iddaa/devam-edenler", style: { color: 'var(--color-primary)' }, onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "\u0130ddaa Kuponlar\u0131m" }), void 0), _jsx(Link, __assign({ to: "hesabim/spor-toto/devam-edenler", style: { color: 'var(--color-primary)' }, onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Spor Toto Kuponlar\u0131m" }), void 0), _jsx(Link, __assign({ to: "hesabim/esya-piyangosu/devam-edenler", style: { color: 'var(--color-primary)' }, onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "E\u015Fya Piyangosu Biletlerim" }), void 0), env.tjk && _jsx(Link, __assign({ to: "hesabim/tjk/devam-edenler", style: { color: 'var(--color-primary)' }, onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "TJK Kuponlar\u0131m" }), void 0), _jsx(Link, __assign({ to: "hesabim/para-yatir", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Para Yat\u0131r" }), void 0), _jsx(Link, __assign({ to: "hesabim/para-cek", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Para \u00C7ek" }), void 0), _jsx(Link, __assign({ to: "hesabim/banka-hesaplarim", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Banka Hesaplar\u0131m" }), void 0), _jsx(Link, __assign({ to: "hesabim/destek-taleplerim/beklemede", className: openPage.includes("hesabim/destek-taleplerim/beklemede") ? "setting-menu" : "", onClick: function () {
                                                document.body.style.overflow = 'unset';
                                            } }, { children: "Destek Taleplerim" }), void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0)] }), void 0) }), void 0) }, void 0));
}
