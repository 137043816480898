var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { myHighLightedMarketGroups } from "../../../services/sportsbook.services";
import { highLightedMarketCategories } from "../../../definitions/constants/market-categories.constant";
import Modal from "react-modal";
import { modalServiceSubject } from "../../../components/modal/modal";
import { useForceUpdate } from "../../../lib/misc.functions";
import { Storage } from "../../../lib/localstorege.service";
export function EventlistHeader(props) {
    var forceUpdate = useForceUpdate();
    var params = props.params;
    var paramsSportSlug = params.sportSlug == 'tumu' ? null : params.sportSlug;
    var parentForceUpdate = props.forceUpdate;
    var _a = useState(false), highLightedMarketModalIsOpened = _a[0], setHighLightedMarketModalIsOpened = _a[1];
    var width = window.innerWidth;
    useEffect;
    // @ts-ignore
    if (width <= 968) {
        if (myHighLightedMarketGroups[paramsSportSlug] && myHighLightedMarketGroups[paramsSportSlug].length > 2) {
            myHighLightedMarketGroups[paramsSportSlug] = myHighLightedMarketGroups[paramsSportSlug].slice(0, 2);
        }
    }
    var marketGroup = myHighLightedMarketGroups[paramsSportSlug];
    var setHighLightedSelectedList = function (market, sportSlug) {
        var indexOfMarket = myHighLightedMarketGroups[sportSlug].indexOf(market);
        if (indexOfMarket !== -1) {
            myHighLightedMarketGroups[sportSlug].splice(indexOfMarket, 1);
        }
        else {
            if (width > 968) {
                if (myHighLightedMarketGroups[sportSlug].length < 3) {
                    myHighLightedMarketGroups[sportSlug].push(market);
                }
                else {
                    modalServiceSubject.next({
                        title: 'Uyarı',
                        content: 'En fazla 3 market seçebilirsiniz',
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0,
                    });
                }
            }
            else {
                if (myHighLightedMarketGroups[sportSlug].length < 2) {
                    myHighLightedMarketGroups[sportSlug].push(market);
                }
                else {
                    modalServiceSubject.next({
                        title: 'Uyarı',
                        content: 'En fazla 2 market seçebilirsiniz',
                        confirm: {
                            cancel: { label: 'Tamam' }
                        },
                        timeout: 0,
                    });
                }
            }
        }
        Storage.set("highLightedMarketCategories", myHighLightedMarketGroups);
        forceUpdate();
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Modal, __assign({ isOpen: highLightedMarketModalIsOpened, onRequestClose: function () {
                    setHighLightedMarketModalIsOpened(false);
                    parentForceUpdate();
                }, ariaHideApp: false, style: { content: { background: 'var(--color-bg-white)', height: "355px", width: "320px", left: "calc( 50% - 160px )", top: "120px" } } }, { children: [_jsx("div", __assign({ className: "modal-header" }, { children: _jsx("h2", { children: "\u00D6ne \u00E7\u0131kan marketleri belirle" }, void 0) }), void 0), _jsx("ul", __assign({ className: "competitionFilterList", style: { height: "225px" } }, { children: highLightedMarketCategories[paramsSportSlug || 'futbol'].map(function (marketGroup, key) {
                            return (_jsx("li", { children: _jsx("ul", { children: _jsxs("li", { children: [_jsx("div", __assign({ className: "float-left" }, { children: _jsxs("label", { children: [_jsx("input", { type: "checkbox", checked: myHighLightedMarketGroups[paramsSportSlug || 'futbol'].indexOf(marketGroup) !== -1, onChange: function () {
                                                                setHighLightedSelectedList(marketGroup, paramsSportSlug);
                                                            } }, void 0), " ", marketGroup.name, " "] }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }, void 0) }, void 0) }, key));
                        }) }), void 0), _jsx("div", __assign({ className: "modal-footer" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("button", __assign({ className: "btn btn-warning", onClick: function () {
                                    setHighLightedMarketModalIsOpened(false);
                                    parentForceUpdate();
                                } }, { children: "Tamam" }), void 0) }), void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "event-card list header" }, { children: [_jsx("div", __assign({ className: "event-card " }, { children: _jsx("div", { className: "clearfix" }, void 0) }), void 0), _jsx("div", { className: "clearfix" }, void 0)] }), void 0)] }, void 0));
}
