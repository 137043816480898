var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useState } from 'react';
import { sportsbookList, sportsSummery, } from '../../store/sportsbook.store';
import MostPlayed from "./widgets/most.played";
import Slider from "./widgets/slider";
import { sportsMenu, initialLeftMenu, dateTimeFormat, useForceUpdate, } from "../../lib/misc.functions";
import "./home.css";
import { betSlipVisibleSubject } from "../../store/betslip.store";
import HighLightedEvents from "./widgets/highlighted.events";
import { Link, useNavigate } from "react-router-dom";
import useSpeechToText from 'react-hook-speech-to-text';
import iconMic from "../../assets/feather/mic.svg";
import iconMicOff from "../../assets/feather/mic-off.svg";
import _ from "lodash";
import Modal from "react-modal";
import { sportSlugId } from "../../definitions/constants/event-status-type.constant";
import { modalServiceSubject } from "../../components/modal/modal";
import { useAuthContext } from "../../store/login.store";
export function SportsList() {
    var openPage = window.location.href;
    var forceUpdate = useForceUpdate();
    var isAuthenticated = useAuthContext().isAuthenticated;
    var navigate = useNavigate();
    var width = window.innerWidth;
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            navigate("/iddaa/" + sportSlug + '/uzunvadeli');
            // document.body.style.overflow = 'unset';
        }
        else {
            navigate("/iddaa/" + sportSlug + '/tumu');
            // document.body.style.overflow = 'unset';
        }
    };
    var isActive = function (sportSlug, eventType) {
        if (window.location.pathname.indexOf(sportSlug) !== -1) {
            if (eventType === '2' && window.location.pathname.indexOf('uzunvadeli') !== -1) {
                return 'active';
            }
            if (eventType === '1' && (window.location.pathname.indexOf('tumu') !== -1 || window.location.pathname.indexOf('canli') !== -1)) {
                return 'active';
            }
        }
        return '';
    };
    return _jsx(_Fragment, { children: _jsx("ul", __assign({ className: "SportListHorizontal" }, { children: sportsSummery.map(function (item, key) {
                return (_jsxs("li", __assign({ onClick: function () {
                        goToUrl(item.sportSlug, item.eventType);
                    }, className: isActive(item.sportSlug, item.eventType) }, { children: [item.activeGame || item.eventType === '2' ? _jsx(_Fragment, { children: _jsxs("div", __assign({ className: "badge nowrap" }, { children: [item.eventType === '2' ? 'UV' : '', item.eventType !== '2' ? _jsxs(_Fragment, { children: [_jsx("i", { className: "".concat(item.activeGame ? 'hasActiveGame' : '') }, void 0), item.liveEventCount] }, void 0) : _jsx(_Fragment, {}, void 0)] }), void 0) }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("div", { className: "icon game-event-icon lg ".concat(item.sportSlug) }, void 0), _jsxs("div", __assign({ className: "label" }, { children: [item.sportName.replace('Hokeyi', 'Hok.').replace('Sporları', 'Spr.'), _jsxs("span", { children: [" ", item.count] }, void 0)] }), void 0)] }), key));
            }) }), void 0) }, void 0);
}
var Home = function () {
    var width = window.innerWidth;
    var navigate = useNavigate();
    useEffect(function () {
        initialLeftMenu(true);
    }, [sportsMenu.isVisible]);
    useEffect(function () {
        betSlipVisibleSubject.next(false);
    }, []);
    var goToUrl = function (sportSlug, eventType) {
        if (eventType === '2') {
            navigate("/iddaa/" + sportSlug + '/uzunvadeli');
            // document.body.style.overflow = 'unset';
        }
        else {
            navigate("/iddaa/" + sportSlug + '/tumu');
            // document.body.style.overflow = 'unset';
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(SearchBar, {}, void 0), _jsxs("div", __assign({ className: "page home" }, { children: [_jsxs("div", __assign({ className: "row" }, { children: [_jsx("div", __assign({ className: "col-lg-4 col-md-6 col-sm-12 left-slider" }, { children: _jsx(Slider, { sliderType: "0" }, void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-8 col-md-6 col-sm-12 right-slider" }, { children: _jsx(Slider, { sliderType: "1" }, void 0) }), void 0)] }), void 0), _jsxs("div", __assign({ className: "row mt-md-3 mt-sm-0" }, { children: [_jsx("div", __assign({ className: "col-lg-4 col-md-5 col-sm-12 pr-0 mostPlayed" }, { children: _jsx(MostPlayed, {}, void 0) }), void 0), _jsx("div", __assign({ className: "col-lg-8 col-md-7 col-sm-12 pl-2 hidden-xs" }, { children: _jsx("div", __assign({ className: "row" }, { children: _jsx("div", __assign({ className: "col-lg-12" }, { children: _jsx(HighLightedEvents, {}, void 0) }), void 0) }), void 0) }), void 0)] }), void 0)] }), void 0)] }, void 0));
};
export default Home;
var speechTimer;
var searchTimer;
export function SearchBar() {
    var _a = React.useState(false), modalIsOpen = _a[0], setIsOpen = _a[1];
    var navigate = useNavigate();
    var _b = useState(''), input = _b[0], setInput = _b[1];
    var _c = useState([]), searchResult = _c[0], setSearchResult = _c[1];
    var _d = useSpeechToText({
        continuous: false,
        useLegacyResults: false,
        timeout: 5,
    }), error = _d.error, interimResult = _d.interimResult, isRecording = _d.isRecording, results = _d.results, startSpeechToText = _d.startSpeechToText, stopSpeechToText = _d.stopSpeechToText;
    useEffect(function () {
        return function () {
            clearTimeout(speechTimer);
            clearTimeout(searchTimer);
            if (isRecording) {
                stopSpeechToText();
            }
            clearTimeout(speechTimer);
            clearTimeout(searchTimer);
        };
    }, []);
    clearTimeout(speechTimer);
    speechTimer = setTimeout(function () {
        if (interimResult) {
            // @ts-ignore
            setInput(interimResult);
            changeHandle(interimResult);
        }
    }, 50);
    var changeHandle = function (keyword) {
        clearTimeout(searchTimer);
        if (keyword.length > 2) {
            searchTimer = setTimeout(function () {
                if (keyword.toLowerCase().includes('para çek')) {
                    navigate('/hesabim/para-cek');
                }
                else if (keyword.toLowerCase().includes('banka hesa')) {
                    navigate('/hesabim/banka-hesaplarim');
                }
                else if (keyword.toLowerCase().includes('destek tale')) {
                    navigate('/hesabim/destek-taleplerim/beklemede');
                }
                else {
                    if (keyword.toLowerCase().split(' ').includes('lig') ||
                        keyword.toLowerCase().split(' ').includes('ligi') ||
                        keyword.toLowerCase().split(' ').includes('bundesliga') ||
                        keyword.toLowerCase().split(' ').includes('lig1') ||
                        keyword.toLowerCase().split(' ').includes('Ligue 1') ||
                        keyword.toLowerCase().split(' ').includes('concacaf') ||
                        keyword.toLowerCase().split(' ').includes('uluslararası') ||
                        keyword.toLowerCase().split(' ').includes('uluslar arası') ||
                        keyword.toLowerCase().split(' ').includes('bölgesellig') ||
                        keyword.toLowerCase().split(' ').includes('euroleague') ||
                        keyword.toLowerCase().split(' ').includes('laliga')) {
                        console.log("competiton search");
                        var competitionResult = [];
                        keyword = keyword.toLowerCase().replace(' ligi', '').replace(' lig', '');
                        console.log("keyword", keyword);
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    // console.log(el.competitionName.toLowerCase(), k.toLowerCase(), el.competitionName.toLowerCase().includes(k.toLowerCase()))
                                    if (el.competitionName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                    if (el.competitionGroupName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['competitionPriority', 'searchScore'], ['asc', 'desc']);
                        var _loop_1 = function (event_1) {
                            if (!competitionResult.find(function (x) {
                                return x.name === event_1.competitionName;
                            })) {
                                competitionResult.push({
                                    name: event_1.competitionName,
                                    sportSlug: event_1.sportSlug,
                                    competitionId: event_1.competitionId,
                                    eventType: event_1.eventType,
                                    count: result.filter(function (e) { return e.competitionName === event_1.competitionName; }).length
                                });
                            }
                        };
                        for (var _i = 0, result_1 = result; _i < result_1.length; _i++) {
                            var event_1 = result_1[_i];
                            _loop_1(event_1);
                        }
                        if (isRecording) {
                            stopSpeechToText();
                        }
                        if (competitionResult.length === 1) {
                            var competition = competitionResult[0];
                            setInput('');
                            navigate('/iddaa/' + competition.sportSlug + '/' + (Number(competition.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + competition.competitionId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(competitionResult.slice(0, 10));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            if (isRecording) {
                                stopSpeechToText();
                            }
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                                timeout: 3000,
                            });
                        }
                    }
                    else {
                        console.log("event search");
                        var result = _.orderBy(sportsbookList.filter(function (x) { return Number(x.eventType) !== 2; }).filter(function (el) {
                            var isMatch = 0;
                            if (keyword.length > 2) {
                                for (var _i = 0, _a = keyword.split(' '); _i < _a.length; _i++) {
                                    var k = _a[_i];
                                    if (el.eventName.toLowerCase().includes(k.toLowerCase())) {
                                        isMatch += 1;
                                    }
                                }
                                if (isMatch > 0) {
                                    el.searchScore = isMatch;
                                    return el;
                                }
                            }
                            return false;
                        }), ['searchScore'], ['desc']);
                        if (isRecording) {
                            stopSpeechToText();
                        }
                        if (result.length === 1) {
                            var event_2 = result[0];
                            setInput('');
                            navigate('/iddaa/' + event_2.sportSlug + '/' + (Number(event_2.eventType) === 2 ? 'uzunvadeli' : 'tumu') + '/' + event_2.competitionId + '/' + event_2.eventId);
                        }
                        else if (result.length > 1) {
                            setSearchResult(result.slice(0, 5));
                            setInput('');
                            setIsOpen(true);
                        }
                        else {
                            if (isRecording) {
                                stopSpeechToText();
                            }
                            modalServiceSubject.next({
                                title: 'Arama Sonucu',
                                content: 'Lütfen aramak istediğiniz takım ismini ya da lig ismi doğru giriniz',
                                timeout: 3000,
                                confirm: {
                                    sure: {
                                        label: 'Temizle ve Tekrarla',
                                        action: function () {
                                            setInput('');
                                            changeHandle('');
                                            // @ts-ignore
                                            document.getElementById('seachBar').focus();
                                        },
                                    }
                                },
                            });
                        }
                    }
                }
            }, 1000);
        }
        else {
            if (isRecording) {
                stopSpeechToText();
            }
        }
        setInput(keyword);
    };
    function closeModal() {
        setIsOpen(false);
    }
    return (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "searchBar visible-sm" }, { children: [_jsx("input", { id: "seachBar", type: "search", autoComplete: "off", placeholder: "Tak\u0131m ad\u0131 ya da lig ismi ile ara", value: input, onChange: function (e) { return changeHandle(e.target.value); } }, void 0), _jsx("button", __assign({ className: isRecording ? 'active' : 'invert-color', onClick: isRecording ? stopSpeechToText : startSpeechToText }, { children: isRecording ? _jsx("img", { src: iconMicOff, width: 14 }, void 0) : _jsx("img", { src: iconMic, width: 14 }, void 0) }), void 0)] }), void 0), _jsx(Modal, __assign({ isOpen: modalIsOpen, onRequestClose: closeModal, style: {
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        background: 'var(--color-bg-white)',
                        marginRight: '-50%',
                        maxWidth: ' calc( 100% - 40px )',
                        padding: '15px',
                        transform: 'translate(-50%, -50%)',
                        width: '650px',
                    }
                }, contentLabel: "" }, { children: _jsxs("div", __assign({ className: "card mt-0" }, { children: [_jsx("h3", { children: "Sonu\u00E7lar" }, void 0), _jsxs("label", { children: [searchResult.length, " sonu\u00E7 bulundu"] }, void 0), _jsx("div", __assign({ className: "card-body p-0" }, { children: _jsx("table", __assign({ className: "table w-100" }, { children: _jsx("tbody", { children: searchResult.map(function (result, key) {
                                        return (_jsxs("tr", __assign({ onClick: function () {
                                                if (result.count) {
                                                    navigate('/iddaa/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId);
                                                }
                                                else {
                                                    navigate('/iddaa/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId + '/' + result.eventId);
                                                }
                                            } }, { children: [_jsxs("td", __assign({ className: "text-left pl-0" }, { children: [result.name === undefined ? _jsxs(_Fragment, { children: [_jsx("i", { className: "sportIcon " + (sportSlugId[result.sportId]) }, void 0), " ", dateTimeFormat(result.eventDate), _jsx("br", {}, void 0)] }, void 0) : _jsx(_Fragment, {}, void 0), _jsx("b", { children: result.name ? result.name : result.eventName }, void 0)] }), void 0), _jsx("td", __assign({ className: "text-left" }, { children: result.count ? _jsx(_Fragment, { children: _jsx(Link, __assign({ className: "btn btn-small btn-primary float-right", to: '/iddaa/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId }, { children: result.count }), void 0) }, void 0) : _jsx(_Fragment, { children: _jsxs(Link, __assign({ className: "btn btn-small btn-primary float-right", to: '/iddaa/' + result.sportSlug + '/' + (result.eventType === 2 ? 'uzunvadeli' : 'tumu') + '/' + result.competitionId + '/' + result.eventId }, { children: ["+", result.marketOutcomeCount] }), void 0) }, void 0) }), void 0)] }), key));
                                    }) }, void 0) }), void 0) }), void 0), _jsx("div", __assign({ className: "card-footer" }, { children: _jsx("input", { className: "btn btn-default mt-3 float-right", onClick: closeModal, type: "button", value: "Vazge\u00E7" }, void 0) }), void 0)] }), void 0) }), void 0)] }, void 0));
}
